import { createStore } from "vuex";
import auth from './modules/auth'
import { IAuthState } from "@/store/modules/auth/state";

export interface IRootState {
    authState: IAuthState;
}

export default createStore({
	modules: {
		auth
	},
});
