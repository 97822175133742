import { ActionTree } from "vuex";
import { IRootState } from "@/store";
import { IAuthState } from "@/store/modules/auth/state";
import { AuthMutation } from "@/store/modules/auth/mutations";
import UserInfoRequest from "@/api/requests/auth/UserInfoRequest";

export enum AuthAction {
	SET_UNAUTHORIZED = "AUTH/IS_UNAUTHORIZED",
	LOAD_USER_INFO = "AUTH/LOAD_USER_INFO",
	VERIFY_INTEGRITY_STAMP = "AUTH/VERIFY_INTEGRITY_STAMP",
}

export const actions: ActionTree<IAuthState, IRootState> = {
	async [AuthAction.LOAD_USER_INFO](ctx) {
		try {
			ctx.state.isUpdatingUserInfo = true;

			const userInfo = await UserInfoRequest.execute();
			ctx.commit(AuthMutation.SET_USER_INFO, userInfo);
		}
		finally {
			ctx.state.isUpdatingUserInfo = false;
		}
	},

	[AuthAction.SET_UNAUTHORIZED](ctx) {
		ctx.commit(AuthMutation.SET_USER_INFO, null);
	},

	async [AuthAction.VERIFY_INTEGRITY_STAMP](ctx, integrityStamp: string) {
		if (ctx.state.isUpdatingUserInfo)
			return;

		if (ctx.state.userInfo === null)
			return;

		// if (process.env.NODE_ENV === "development") {
		// 	console.log("VERIFY_INTEGRITY_STAMP",
		// 		"| NEW: " + integrityStamp,
		// 		"| CURRENT: " + ctx.state.userInfo.IntegrityStamp,
		// 		"| EQUALS:", (integrityStamp === ctx.state.userInfo.IntegrityStamp));
		// }

		if (integrityStamp === ctx.state.userInfo.IntegrityStamp)
			return;

		try {
			await ctx.dispatch(AuthAction.LOAD_USER_INFO);
		}
		catch (err) {
			console.log("VERIFY_INTEGRITY_STAMP error:", err);
		}
	},
};
