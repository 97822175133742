export enum UserType {
	Colaborador = 1,
	Administrador = 2,
	Gestor = 3,
	AdminMaster = 4,
}

export default interface UserInfoDto {
	UserId: string;
	Name: string;
	Type: UserType;
	ImageId: string;
	IntegrityStamp: string;
	Permissions: string[];
	Modules: string[];
}