import Api from "@/api";
import { JourneyType } from "@/models/Admin/Users";

export default class GetTodayHoursJourneyRequest {
	
	async execute(): Promise<TodayJourneyInfo> {
		const response = await Api.get("/gerenciamento/usuarios/user-today-work-journey");
		return response.data;
	}
}

interface TodayJourneyInfo {
	JourneyType: JourneyType,
	DayHoursJourney: string,
	DayPeriodJourney: {
		Begin: string,
		End: string,
		BreakBegin: string,
		BreakEnd: string
	}
}
