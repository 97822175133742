import { CardRecordType } from "@/api/requests/card-record/GetUserCardsRecordsRequest";

export class TodayHoursBank {
	WorkJourney = "";
	RegisteredTime = "";
	HoursBank = "";
	LastRegister: CardRecordType = 0;
	HoursBankClass = "";
	Percent = 0;
	IsExtraDay = false;
	IsInVacation = false;
	ExibitionText = "";
}