import { RouteRecordRaw } from "vue-router";
import RouteName from "@/router/routes-name";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/meus-registros",
		name: RouteName.MY_REGISTERS,
		meta: {
			registersModuleOnly: true,
			title: "Meus registros",
		},
		component: () => import(/* webpackChunkName: "hours" */ "@/views/Hours/MyRegisters.vue"),
	},
	{
		path: "/meus-registros/ajustar/:cardRecordId",
		name: RouteName.ADJUSTMENTS,
		meta: {
			registersModuleOnly: true,
			title: "Solicitar ajuste",
		},
		props: true, //o props permite o componente acessar os parametros da rota.
		component: () => import(/* webpackChunkName: "hours" */ "@/views/Hours/Adjust.vue"),
	},
	{
		path: "/banco-de-horas",
		name: RouteName.BANK,
		meta: {
			title: "Banco de horas",
		},
		component: () => import/* webpackChunkName: "hours" */ ("@/views/Hours/BankView.vue"),
	},
];

export default routes;