import Api from "@/api";
import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";

export default class GetTaskAvailableMeetingTimes {

	MeetingRoomId = "";
	// Date YYYY-MM-DDTHH:mm:SS
	MeetingDate = ""; 

	async execute(): Promise<DefaultListResponse<AvailableMeetingTimes>> {
		const response = await Api.get("/task/meeting-times", {
			params: this,
		});
		return response.data;
	}
}

interface AvailableMeetingTimes {
	Begin: string,
	End: string
}