import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OwnIcon = _resolveComponent("OwnIcon")!
  const _component_NotificationContainer = _resolveComponent("NotificationContainer")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-small btn-secondary",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeTheme && _ctx.changeTheme(...args))),
      "aria-label": "Mudar tema claro/escuro"
    }, [
      (_ctx.isDarkMode)
        ? (_openBlock(), _createBlock(_component_OwnIcon, {
            key: 0,
            type: "sun"
          }))
        : (_openBlock(), _createBlock(_component_OwnIcon, {
            key: 1,
            type: "moon"
          }))
    ]),
    _createVNode(_component_NotificationContainer)
  ]))
}