import { AbsenceType } from "@/api/requests/absenses/GetAbsencesRequest";

export class Absence {
	AbsenceJustificationId = "";
	UserName = "";
	ImageUrl = "";
	UserId = "";
	Message = "";
	BeginDate = "";
	EndDate = "";
	CreatedDate = "";
	Status = "";
	StatusClass = "";
	RepliedBy = "";
	UserNameReplied = "";
	RepliedByTitle = "";
	ReplyDate = "";
	Type: AbsenceType = AbsenceType.AtestadoMedico;
	TypeText = "";
	TypeDetail = "";
	Files: File[] | null = [];
	Documents: DocumentInfo[] = []
}

export const absenceTypes: { type: AbsenceType, text: string }[] = [
	{
		type: AbsenceType.AtestadoMedico,
		text: "Atestado médico",
	}, {
		type: AbsenceType.ConsultaMedicaFilho,
		text: "Consulta médica (filho)",
	}, {
		type: AbsenceType.ConsultaMedicaEsposa,
		text: "Consulta médica (esposa)",
	}, {
		type: AbsenceType.FalecimentoFamiliar,
		text: "Falecimento familiar",
	}, {
		type: AbsenceType.Casamento,
		text: "Casamento",
	}, {
		type: AbsenceType.NascimentoFilho,
		text: "Nascimento filho",
	}, {
		type: AbsenceType.DoacaoSangue,
		text: "Doação de sangue",
	}, {
		type: AbsenceType.AlistamentoEleitoral,
		text: "Alistamento eleitoral",
	}, {
		type: AbsenceType.ServicoMilitar,
		text: "Serviço militar",
	}, {
		type: AbsenceType.Vestibular,
		text: "Vestibular",
	}, {
		type: AbsenceType.Juizo,
		text: "Juízo",
	}, {
		type: AbsenceType.RepresentacaoSindical,
		text: "Representação sindical",
	}, {
		type: AbsenceType.ExameMedicoCancer,
		text: "Exame médico preventivo (câncer)",
	}, {
		type: AbsenceType.Greve,
		text: "Greve",
	}, {
		type: AbsenceType.LicencaMaternidade,
		text: "Licença maternidade",
	}, {
		type: AbsenceType.Mesario,
		text: "Mesário",
	}, {
		type: AbsenceType.DoacaoLeiteMaterno,
		text: "Doação de leite materno",
	}, {
		type: AbsenceType.ProblemaTransportePublico,
		text: "Problema no transporte público",
	},{
		type: AbsenceType.Outros,
		text: "Outros",
	},
];

export class DocumentInfo {
	DocumentId = "";
	Description = "";
	Size = "";
	IconClass = ""
}