<script setup lang="ts">
import { defineProps, PropType } from "vue";
import { Tag } from "@/models/Tasks/Tag";
import { defineEmits } from "vue/dist/vue";

const emit = defineEmits([
	"clickRemove",
]);

const props = defineProps({
	tag: {
		type: Object as PropType<Tag>,
		required: true,
	},
	small: {
		type: Boolean,
		default: false,
	},
	compact: {
		type: Boolean,
		default: false,
	},
	isEditting: {
		type: Boolean,
		default: false,
	},
});

function removeFromTask() {
	if (props.isEditting)
		emit("clickRemove", props.tag);
}
</script>

<template>
	<span :title="props.tag?.Name" class="tag-label" :style="'background-color:' + props.tag?.Color + (props.compact ? '' : '90')" :class="{small: props.small, compact: props.compact}">
		<span class="tag-text"> 
			{{ props.tag.Name }}
		</span>
		<button class="btn btn-invisible btn-close" v-if="props.isEditting" @click="removeFromTask"></button>
	</span>
</template>

<style lang="scss">
@import "@/styles/Variables.scss";

.tag-label {
	padding: 0.3rem 0.5rem 0.3rem 0.4rem;
	border-radius: 10rem;
	display: flex;
	flex-grow: 1;
	align-items: center;
	gap: 0.5rem;
	border: 1px solid $ot-background-secondary;

	.tag-text {
		font-weight: 600;
		//mix-blend-mode: overlay;
		color: #fff;
		line-height: 0;
	}

	&:before {
		content: "";
		display: inline-block;
		height: 0.8rem;
		width: 0.8rem;
		border-radius: 100%;
		mix-blend-mode: overlay;
		background-color: #000;
	}


	&.small {
		font-size: 0.8rem;
		padding: 0.2rem 0.4rem 0.2rem 0.2rem;
		gap: 0.4rem;
		//width: 6rem;

		&:before {
			height: 0.6rem;
			width: 0.6rem;
		}
	}

	&.compact {
		.tag-text,
		&:before {
			display: none;
		}
		//width: unset;
		min-width: 3rem;

	}
}

body:has(#app > main.dark) {
	.tag-label {
		.tag-text {
			color: #fff
		}

		&:before {
			background-color: #fff;
		}
	}
}

</style>