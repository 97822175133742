import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetProjectsInfoRequest {

	async execute(): Promise<DefaultListResponse<ProjectInfo>> {
		const response = await Api.get("/projects/get-projects-info");
		return response.data;
	}
}

interface ProjectInfo {
	ProjectId: string,
	ProjectName: string,
}