import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";
import { HoursJourney, PeriodJourney } from "@/api/requests/account/GetAccountRequest";
import { JourneyType } from "@/models/Admin/Users";

export class GetUserPreviousWorkJourney {

	async execute(): Promise<DefaultListResponse<UserJourney>> {
		const response = await Api.get("/gerenciamento/usuarios/get-previous-work-journey");
		return response.data;
	}
}

interface UserJourney {
	JourneyType: JourneyType,
	HoursJourney: HoursJourney,
	PeriodJourney: PeriodJourney,
	StartDate: string,
}