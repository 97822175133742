import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class EditStateChecklistRequest {
	Record: number | null = null;
	IsDone = false;

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.put("/task/change-check-list", this);
		return response.data;
	}
}

