<template>
	<div :class="props.selectTeamMember ? 'teams-search-user' : 'activities-search-user'">
		<input class="form-control" id="addMember" type="text" placeholder="Buscar usuário..." ref="userSearchElement" v-model="userSearchInput" autocomplete="off">
		<div class="list-group">
			<button type="button" v-for="(user, idx) in userAvailableList" :key="idx" class="list-group-item list-group-item-action" @click="addMember(user)">
				<UserImage :name="user.Name" :img-url="user.ImageUrl" big />
			</button>
		</div>
		<button class="btn btn-secondary btn-all" :disabled="userAvailableList.length <= 0" @click="addAllUsers">Adicionar todos os usuários</button>
	</div>
</template>

<script setup lang="ts">

import { MemberInfo } from "@/models/Tasks/TasksView";
import { computed, defineEmits, defineProps, PropType, ref } from "vue";
import UserImage from "@/components/UserImage.vue";

const userSearchElement = ref();
const userSearchInput = ref("");

const emit = defineEmits([
	"userAdded",
	"allUsersAdded",
]);

const props = defineProps({
	userAvailableList: {
		type: Array as PropType<MemberInfo[]>,
		required: true,
	},
	selectTeamMember: {
		type: Boolean,
		default: false,
	},
});

const userAvailableList = computed(() => {
	if (userSearchInput.value.length === 0)
		return props.userAvailableList;

	const filteredInput = userSearchInput.value.toLowerCase()
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");

	return props.userAvailableList.filter((user: MemberInfo) =>
		user.Name.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.includes(filteredInput),
	);
});

function addMember(user: MemberInfo) {

	emit("userAdded", user);

	userSearchInput.value = "";
	userSearchElement.value.focus();
}

function addAllUsers() {
	emit("allUsersAdded");
}

</script>

<style lang="scss">

@import "@/styles/Variables.scss";

.activities-search-user {
	position: absolute;
	top: 3rem;
	background-color: $ot-background-primary;
	border: 1px solid hsl(210deg 35% 91%);
	padding: 0.5rem;
	border-radius: 1.5rem;
	z-index: 2;

	.list-group {
		overflow-y: auto;
		max-height: 40vh;
		border-radius: 5%;
		margin-top: 0.5rem;

		.list-group-item {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
	}

	.btn-all {
		width: 100%;
		margin-top: 0.5rem;
	}
}

body:has(#app > main.dark) {
	.members .activities-search-user {
		background-color: $ot-dark-background-primary;
		border: 1px solid $ot-dark-background-primary;
	}
}

.teams-search-user {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}


</style>