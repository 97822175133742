<template>
	<component :class="{dark: isDarkMode, 'is-development': isDevelopment}" :is="layout"></component>
</template>

<script lang="ts">
import {computed, defineComponent} from "vue";
import {useRoute} from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import {AuthGetter} from "@/store/modules/auth/getters";
import {useStore} from "vuex";

export default defineComponent({
	components: {
		DefaultLayout,
		EmptyLayout,
	},
	setup() {
		const route = useRoute();
		const layout = computed(() => {
			// No carregamento inicial da página utilizar o empty-layout
			if (typeof route.name === "undefined") {
				return "empty-layout";
			}
			return (route.meta.layout || "default") + "-layout";
		});

		const store = useStore();
		const isDarkMode = computed(() => store.getters[AuthGetter.IS_DARK_MODE]);
		const isDevelopment = process.env.NODE_ENV === "development";

		// let body = document.getElementsByTagName("body")[0]
		//
		// function checkBackdrop() {
		// 	// Se modal está aberto
		// 	// Aparentemente, não usam mais essa classe para verificar se há modal aberto.
		// 	// if (body.classList.contains('modal-open')) {
		// 	let backdropArray = body.getElementsByClassName('modal-backdrop')
		// 	console.log(backdropArray)
		// 	if (backdropArray.length > 1)
		// 		for (let i = 0; i < backdropArray.length; i++) {
		// 			if (i > 1)
		// 				backdropArray[i].remove()
		// 		}
		// 	// }
		// }
		//
		// const observer = new MutationObserver((mutationList, observer) => {
		// 	mutationList.forEach(function (mutation) {
		// 		if (mutation.attributeName === 'class') {
		// 			checkBackdrop()
		// 		}
		// 	})
		// })
		//
		// observer.observe(body, {attributes: true})


		return {
			layout,
			isDarkMode,
			isDevelopment,
		};
	},
});
</script>
<style lang="scss">
@import "@/styles/Variables.scss";

.is-development {
	border-top: 6px solid $ot-text-pending;
}
</style>