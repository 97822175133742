import axios from "axios";
import { AuthAction } from "@/store/modules/auth/actions";
import store from "@/store";
import Qs from "qs";

const axiosInstance = axios.create({
	baseURL: process.env.NODE_ENV === "production" ? "https://" + window.location.host + "/api" : "http://localhost:5000/api",
	withCredentials: true,
	headers: {},
	paramsSerializer: function (params) {
		return Qs.stringify(params, {arrayFormat: 'indices'})
	},
});

axiosInstance.interceptors.response.use(function (response) {
	// Status 2xx

	// if (process.env.NODE_ENV === "development")
	// 	console.log(` -- AXIOS ${response.config.method?.toUpperCase()} ${response.config.url} => ${response.statusText} (${response.status})\n`, response);

	if ("x-integrity-stamp" in response.headers) {
		store.dispatch(AuthAction.VERIFY_INTEGRITY_STAMP, response.headers["x-integrity-stamp"]);
	}

	return response;
}, async function (error) {
	// Status diferente de 2xx
	// console.log(` -- AXIOS ${error.response.config.method?.toUpperCase()} ${error.response.config.url} => ${error.response.statusText} (${error.response.status}): ${error.message}\n`, error);

	if (error.response.status === 401) {
		await store.dispatch(AuthAction.SET_UNAUTHORIZED);
	}

	return Promise.reject(error);
});

export default axiosInstance;