import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class DeleteTaskLogCommentRequest {
	Record = -1;
	IsDeleted = false;

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.put("/task-log/commentary", this);
		return response.data;
	}
}