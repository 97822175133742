import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class CreateCardRecordRequest {
	Type = 1;
	
	constructor(type: number) {
		this.Type = type
	}

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.post("/card-records", this);
		return response.data;
	}
}