<template>
	<i :class="['ri-' + props.type + (props.noFillNoLine ? '' : '-' + fillOption), {'extra-small': extraSmall, 'small': small, 'large': large, 'extra-large': extraLarge}]"></i>
</template>

<script setup lang="ts">

import {computed, defineProps} from "vue";

const props = defineProps({
	fill: {
		type: Boolean,
		default: false,
	},
	type: {
		type: String,
		required: true,
	},
	noFillNoLine: {
		type: Boolean,
		default: false,
	},
	extraSmall: {
		type: Boolean,
		default: false,
	},
	small: {
		type: Boolean,
		default: false,
	},
	large: {
		type: Boolean,
		default: false,
	},
	extraLarge: {
		type: Boolean,
		default: false,
	},
});

const fillOption = computed(() => props.fill ? "fill" : "line");
</script>
<style scoped lang="scss">
i {
	margin: 0.25rem;
	font-size: 1rem;
}

.extra-large {
	font-size: 1.5em;
	line-height: 0.6666em;
	vertical-align: -.075em;
}

.large {
	font-size: 1.3333em;
	line-height: 0.75em;
	vertical-align: -.0667em;
}

.small {
	font-size: .875em;
}

.extra-small {
	font-size: .75em;
}
</style>