export class Tag {
	TagId = "";
	Name = "";
	Color = "#47c274";
	CanEdit = false;
}

export class NewTag extends Tag {
	TeamId: string | null = null;
}

export class TeamTags {
	TeamId: string | null = "";
	Name = "";
	Tags: Tag[] = [];
}