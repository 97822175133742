<template>
	<section class="tag-container" :class="{open: isOpen, selector: props.selector}">
		<aside class="tag-list-container">
			<input class="form-control" type="text" v-model="searchTags" placeholder="Buscar etiquetas...">
			<div class="tag-list">
				<section class="team-list" v-if="isLoadingTags">
					Carregando...
				</section>
				<section class="team-list" v-for="(team, i) in props.selector ? tagList.filter(t => t.TeamId === props.taskTeam || t.TeamId === null) : tagList" :key="i" v-else>
					{{ team.Name }}
					<div class="tag-label-container" v-for="(tag, j) in team.Tags.filter(t => t.Name.toLowerCase().includes(searchTags.toLowerCase()))" :key="j">
						<div class="form-check" v-if="props.selector">
							<input class="form-check-input" type="checkbox" :name="team.Name + tag.Name" @change="addTag(tag)" :checked="props.tagsInTask?.some(t => t.TagId == tag.TagId )">
						</div>
						<TagLabel :tag="tag" />
						<button class="btn btn-invisible" title="Editar" @click="setEditTag(tag, team.TeamId)" v-if="tag.CanEdit">
							<OwnIcon type="pencil"/>
						</button>
					</div>
				</section>
			</div>
			<button class="btn btn-secondary" v-if="!isOpen" @click="openCreateTag">
				<OwnIcon type="price-tag-3" fill/>
				Criar etiqueta
			</button>
		</aside>
		<form class="create-tag" @submit.prevent="submitFormTag" v-if="isOpen">
			<section class="create-tag-title">
				<OwnIcon type="price-tag-3" fill/>
				{{ isEditting ? "Editar" : "Criar" }} etiqueta
			</section>
			<section class="create-tag-content">
				<label class="form-label" for="title-tag">Título</label>
				<input class="form-control" type="text" id="title-tag" v-model="newTag.Name" placeholder="Insira o título...">
			</section>
			<section class="create-tag-content">
				<label class="form-label" for="color-tag">Cor</label>
				<input class="form-control form-control-color" type="color" id="color-tag" v-model="newTag.Color">
			</section>
			<section class="create-tag-content">
				<label class="form-label" for="color-tag">Equipe</label>
				<div v-if="props.selector && props.taskTeam != 'null'">
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="teamSelected" id="taskTeamSelected" :value="props.taskTeam" v-model="newTag.TeamId" />
						<label class="form-check-label" for="taskTeamSelected">
							{{ teams.find(t => t.TeamId == props.taskTeam).Name }}
						</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="teamSelected" id="globalSelected" :value="null" v-model="newTag.TeamId" />
						<label class="form-check-label" for="globalSelected">
							Global
						</label>
					</div>
				</div>
				<select class="form-select" id="team-tag" v-model="newTag.TeamId" v-else>
					<option v-for="(team, idx) in teams.filter(t => t.UserIsPartOfTheTeam)" :key="idx" :value="team.TeamId">
						{{ team.Name }}
					</option>
				</select>
			</section>
			<button class="btn btn-primary">
				<OwnIcon type="price-tag-3" fill/>
				{{ isEditting ? "Editar" : "Criar" }}
			</button>
			<button class="btn btn-danger" type="button" v-if="!props.selector && isEditting" @click="deleteTag(newTag)">
				<OwnIcon type="delete-bin-2"/>
				Excluir
			</button>
			<button class="btn btn-secondary" type="button" @click="close">
				Cancelar
			</button>
		</form>
	</section>
</template>

<script setup lang="ts">

import { onMounted, ref, defineEmits, defineProps, defineExpose } from "vue";
import { NewTag, Tag, TeamTags } from "@/models/Tasks/Tag";
import GetTagsRequest from "@/api/requests/tag/GetTagsRequest";
import { notify } from "@kyvg/vue3-notification";
import { DefaultError, DefaultWarn, NotificationType } from "@/utils/general";
import { Team } from "@/models/Admin/Teams";
import GetTeamsInfoRequest from "@/api/requests/teams/GetTeamsInfoRequest";
import CreateTagRequest from "@/api/requests/tag/CreateTagRequest";
import { loadStart, loadStop } from "@/utils/loading";
import EditTagRequest from "@/api/requests/tag/EditTagRequest";
import TagLabel from "@/components/TagLabel.vue";
import { PropType } from "vue/dist/vue";
import OwnIcon from "@/components/OwnIcon.vue";

const isOpen = ref(false);
const isEditting = ref(false);

const emit = defineEmits([
	"tagToDelete",
	"addTagToTask",
]);

const props = defineProps({
	selector: {
		type: Boolean,
	},
	tagsInTask: {
		type: Array as PropType<Tag[]>,
	},
	taskTeam: {
		type: String,
		default: null
	}
});

function openCreateTag() {
	newTag.value.Name = "";
	newTag.value.Color = "#47C274";
	isOpen.value = true;
}

function openEditTag() {
	isOpen.value = true;
}

function close() {
	isEditting.value = false;
	isOpen.value = false;
}

const searchTags = ref("");

const tagList = ref<TeamTags[]>([]);

const newTag = ref<NewTag>(new NewTag());
const teams = ref<Team[]>([]);

const isLoadingTags = ref(false);

async function loadTags() {
	try {

		isLoadingTags.value = true;

		const request = new GetTagsRequest();
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			tagList.value = [...response.Result.Teams];

			if (response.Result.Global.length > 0) {
				let globalTag = new TeamTags();
				globalTag.Name = "Global";
				globalTag.Tags = response.Result.Global;
				globalTag.TeamId = null;

				tagList.value.push(globalTag);
			}

		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		isLoadingTags.value = false;
	}
}

async function createTag() {
	try {
		loadStart();
		const request = new CreateTagRequest();
		request.Name = newTag.value.Name;
		request.Color = newTag.value.Color;
		request.TeamId = newTag.value.TeamId;
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Etiqueta ${newTag.value.Name} criada com sucesso.`,
			});
			await loadTags();
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		close();
		loadStop();
	}
}

async function editTag() {
	try {
		loadStart();
		const request = new EditTagRequest();
		request.TagId = newTag.value.TagId;
		request.Name = newTag.value.Name;
		request.Color = newTag.value.Color;
		request.TeamId = newTag.value.TeamId;
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Etiqueta ${newTag.value.Name} editada com sucesso.`,
			});
			await loadTags();
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		close();
		isEditting.value = false;
		loadStop();
	}
}

async function loadTeams() {
	try {
		const request = new GetTeamsInfoRequest();
		const response = await request.execute();

		teams.value = [];

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			let _team = new Team();
			_team.Name = "Global";
			_team.TeamId = null;
			_team.UserIsPartOfTheTeam = true
			teams.value.push(_team);

			for (const teamInfo of response.Result.Records) {
				let team = new Team();
				team.Name = teamInfo.Name;
				team.TeamId = teamInfo.TeamId;
				team.UserIsPartOfTheTeam = teamInfo.UserIsPartOfTheTeam
				teams.value.push(team);
			}
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		//
	}
}

function setEditTag(tag: Tag, teamId: string | null) {
	newTag.value.TagId = tag.TagId;
	newTag.value.TeamId = teamId;
	newTag.value.Name = tag.Name;
	newTag.value.Color = tag.Color;
	isEditting.value = true;
	openEditTag();
}

function submitFormTag() {
	return isEditting.value ? editTag() : createTag();
}

function deleteTag(tag: NewTag) {
	emit("tagToDelete", tag);
}

function addTag(tag: Tag) {
	if (props.selector && props.tagsInTask)
		emit("addTagToTask", tag);
}

defineExpose({
	close,
	loadTags,
})

onMounted(async () => {
	await Promise.all([loadTeams(), loadTags()]);
});

</script>

<style lang="scss">

@import "@/styles/Variables.scss";

.tag-container {
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 1rem;

	&.open {
		.tag-list {
			max-height: 15rem;
		}
	}

	&.selector {
		position: absolute;
		top: 2.5rem;
		background-color: $ot-background-primary;
		border: 1px solid hsl(210deg 35% 91%);
		padding: 0.5rem;
		border-radius: 1.5rem;
		z-index: 2;
		min-width: 20rem;
	}

	.tag-list-container, .tag-list, .team-list, .create-tag {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.tag-label-container {
		display: flex;
	}

	.tag-list {
		background-color: $ot-background-secondary;
		border-radius: 1rem;
		flex-grow: 1;
		overflow-y: auto;
		padding: 1rem;
		max-height: 30rem;
	}

	.create-tag {
		padding-left: 1rem;

		.form-control-color {
			width: 50%;
			padding: 0;
			border-radius: 5px;
		}
	}

	.tag-list-container + .create-tag {
		border-top: 1px solid $ot-background-secondary;
		padding: 1rem 0 0;
	}

	@media (max-width: 450px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));

		.tag-list {
			height: 15rem;
		}
	}
}

body:has(#app > main.dark) {
	.tag-container {
		&.selector {
			background-color: $ot-dark-background-primary;
			border: 1px solid $ot-dark-background-primary;
		}
	}
}
</style>