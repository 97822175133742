import { MutationTree } from "vuex";
import { IAuthState } from "./state";
import UserInfoDto from "@/api/responses/auth/UserInfoDto";

export enum AuthMutation {
	SET_USER_INFO = "AUTH/SET_USER_INFO",
	CHANGE_THEME = "AUTH/CHANGE_THEME",
}

export const mutations: MutationTree<IAuthState> = {
	[AuthMutation.SET_USER_INFO](state: IAuthState, payload: UserInfoDto | null) {
		state.userInfo = payload;
	},
	[AuthMutation.CHANGE_THEME](state: IAuthState) {
		state.isDarkMode = !state.isDarkMode;
		localStorage.setItem("isDarkMode", JSON.stringify(state.isDarkMode));
	},
};