import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";
import Api from "@/api";

export default class UnarchiveTaskRequest {
	TaskId = "";
	
	constructor(taskId: string) {
		this.TaskId = taskId
	}

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.put("/task/unarchive-task", this);
		return response.data;
	}
}