
export class TodayHoursJourney {
	JourneyType = 0;
	DayHoursJourney = "";
	DayPeriodJourney = {
		Begin: "",
		End: "",
		BreakBegin: "",
		BreakEnd: "",
	};
}