import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mty-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_component_notifications, {
      position: "bottom right",
      duration: 5000,
      speed: 200
    })
  ]))
}