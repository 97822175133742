import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";
import Api from "@/api";

export default class ReadNotificationRequest{
	NotificationId = "";
	
	async execute(): Promise<DefaultBooleanResponse>{
		const response = await Api.put("/notifications/read-notification?NotificationId=" + this.NotificationId);
		
		return response.data
	}
}