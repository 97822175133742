<template>
	<main id="default">
		<SidebarLayout/>
		<section class="main-component">
			<div class="header">
				<h1 class="title-component">{{ routeTitle }}</h1>
				<SearchLayout/>
				<RegisterContainer v-if="(routeTitle !== 'Geral') && hasRegistersModule"/>
			</div>
			<router-view :key="routePath"/>
		</section>
		<notifications classes="owntec-notification" :duration="9000" :speed="200" width="100%" position="bottom" />
		
		<!--Modal de atividades-->
		<TasksModal />
	</main>
</template>

<script setup lang="ts">

import { computed, onBeforeMount, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import SidebarLayout from "@/layouts/SidebarLayout.vue";
import SearchLayout from "@/layouts/SearchLayout.vue";
import { JourneyType } from "@/models/Admin/Users";
import moment from "moment/moment";
import GetTodayHoursJourneyRequest from "@/api/requests/bank/GetTodayHoursJourneyRequest";
import { TodayHoursJourney } from "@/models/Hours/TodayHoursJourney";
import { useStore } from "vuex";
import { AuthGetter } from "@/store/modules/auth/getters";
import RegisterContainer from "@/components/RegisterContainer.vue";
import TasksModal from "@/components/TasksModal.vue";

const router = useRouter();
const routePath = computed(() => router.currentRoute.value.path);
const routeTitle = computed(() => router.currentRoute.value.meta.title);

const todayHoursJourney = ref<TodayHoursJourney>(new TodayHoursJourney());

//Variáveis da Store
const store = useStore();
const hasRegistersModule = computed(() => store.getters[AuthGetter.HAS_REGISTERS_MODULE]);

/**
 * Solicita à API os dados de horários da Jornada do dia.
 */
const requestTodayHoursJourney = async () => {
	try {
		const request = new GetTodayHoursJourneyRequest();
		const response = await request.execute();


		todayHoursJourney.value.JourneyType = response.JourneyType;
		todayHoursJourney.value.DayHoursJourney = response.DayHoursJourney;
		todayHoursJourney.value.DayPeriodJourney = response.DayPeriodJourney;

	}
	catch (err) {
		console.warn(err);
	}
};

/**
 * Informa o usuário pelo sistema operacional (Windows, Android) de que está na hora de registrar o ponto
 */
const notifyJourneyTime = () => {
	if (todayHoursJourney.value.JourneyType == JourneyType.Period) {
		let period = todayHoursJourney.value.DayPeriodJourney;

		if ([period.Begin, period.BreakBegin, period.BreakEnd, period.End].includes(moment().locale("pt-br").format("LT"))) {
			// Verifica se o browser suporta notificações
			if (!("Notification" in window)) {
				console.warn("Este browser não suporta notificações de Desktop");
			}

			// Se notificações foram permitidas
			else if (Notification.permission === "granted") {
				new Notification("OwnTime", {
					lang: "pt-br",
					body: "Não esqueça de registrar seu ponto!",
					icon: "/favicon/ms-icon-150x150.png",
					badge: "/favicon/favicon-16x16.png",
					vibrate: [100, 50, 100],
				});
			}

			// Se ainda não foram permitidas mas não foram negadas
			else if (Notification.permission !== "denied") {
				Notification.requestPermission();
			}
		}
	}
};

let intervalRequestTodayHoursJourney = ref<number>(0);

onBeforeMount(async () => {

	if (hasRegistersModule.value) {
		await requestTodayHoursJourney();
		notifyJourneyTime();
	}

	if (hasRegistersModule.value) {

		intervalRequestTodayHoursJourney.value = setInterval(async () => {
			
			await requestTodayHoursJourney();
			notifyJourneyTime();

		}, 5 * 60 * 1000);
	}
});

onUnmounted(() => {
	clearInterval(intervalRequestTodayHoursJourney.value);
});

</script>

<style lang="scss">
main#default {

	display: flex;
	height: 100vh;
	overflow: hidden;


	.header {
		display: flex;
		justify-content: space-between;
		margin-top: 4rem;
		margin-bottom: 1rem;
	}

	.main-component {
		padding: 0 1.25rem;
		flex-grow: 1;
		min-height: 100vh;
		overflow-y: auto;
	}

	@media (max-width: 992px) {
		.header {
			display: flex;
			flex-flow: wrap;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}
}


</style>