import { StageTypes } from "@/api/requests/task/GetTaskRequest";
import { Tag } from "@/models/Tasks/Tag";

export class Project {
	ProjectId: string | null = "";
	Name = "";
	BeginDate: string | null = "";
	EndDate: string | null = "";
	Color = "#02446d";
	Description = "";
	ParentProjectId: string | null = null;
	ParentProjectName = "";
	TotalTasks = 0;
	DoneTasks = 0;
	Attachments: ProjectAttachment[] = [];
	SubProjects: SubProject[] = [];
	Activities: ProjectTask[] = [];
	HasProjectSon = false;
	Hours: ProjectHoursTeam[] = [];
	TotalProjectTime = "";
}

export class ProjectAttachment {
	ProjectId = "";
	ProjectAttachmentId = "";
	FileName = "";
	File: File | null = null;
	SendDate = "";
	Size = "";
	IconClass = "";
	ColorClass = "";
}

export class SubProject {
	ProjectId: string | null = "";
	Name = "";
	BeginDate: string | null = "";
	EndDate: string | null = "";
	Color = "#02446d";
	TotalTasks = 0;
	DoneTasks = 0;
}

export class ProjectTask {
	TaskId = "";
	Name = "";
	Points = 0;
	Priority = "";
	Stage: StageTypes = 0;
	StageText = "";
	EndDate = "";
	Islate = true;
	TeamName = "";
	Members: ProjectTaskMember[] = [];
	Effort: string | null = null;
	Tags: Tag[] = [];
}

export class ProjectTaskMember {
	UserId = "";
	ImageUrl = "";
	Name = "";
}

export class ProjectHoursTeam {
	TeamId = "";
	TeamName = "";
	TotalTasks = 0;
	DoneTasks = 0;
	TotalTaskHours = "";
	Users: ProjectHoursTeamUser[] = [];
}

export class ProjectHoursTeamUser {
	UserId = "";
	ImageUrl = "";
	Name = "";
	TotalTasks = 0;
	DoneTasks = 0;
	UserTotalTime = "";
}

export enum TAB {
	Description,
	Attachments,
	Activities,
	Projects,
	Hours,
}