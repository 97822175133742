import Api from "@/api";
import { DefaultBooleanResponseResult } from "@/api/responses/generic/DefaultBooleanResponse";

export default class GetTagsRequest {

	Search = "";

	constructor(search = "") {
		this.Search = search;
	}

	async execute(): Promise<DefaultBooleanResponseResult<TagListInfo>> {
		const response = await Api.get("/tag", {
			params: this,
		});
		return response.data;
	}
}

interface TagListInfo {
	Teams: TeamTagInfo[],
	Global: TagInfo[],
}

interface TeamTagInfo {
	TeamId: string;
	Name: string;
	Tags: TagInfo[];
}

export interface TagInfo {
	TagId: string;
	Name: string;
	Color: string;
	CanEdit: boolean;
}