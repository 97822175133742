import { GetterTree } from "vuex";
import { IRootState } from "@/store";
import { IAuthState } from "./state";
import { UserType } from "@/api/responses/auth/UserInfoDto";

export enum AuthGetter {
	USER_INFO = "AUTH/USER_INFO",
	USERNAME = "AUTH/USERNAME",
	IMAGE_ID = "AUTH/IMAGE_ID",
	IS_ADMIN = "AUTH/IS_ADMIN",
	IS_ADMIN_MASTER = "AUTH/IS_ADMIN_MASTER",
	IS_GESTOR = "AUTH/IS_GESTOR",
	USER_TYPE = "AUTH/USER_TYPE",
	IS_DARK_MODE = "AUTH/IS_DARK_MODE",
	IS_LOGGED_IN = "AUTH/IS_LOGGED_IN",
	HAS_TASKS_MODULE = "AUTH/HAS_TASKS_MODULE",
	HAS_REGISTERS_MODULE = "AUTH/HAS_REGISTER_MODULE",
}

const tasksModuleId = "7e0c0311-7039-467c-af6d-dd36967c83e3";
const registersModuleId = "dc916216-3452-418d-8b76-31e8e72cb5cb";

export const getters: GetterTree<IAuthState, IRootState> = {
	[AuthGetter.USER_INFO](state: IAuthState) {
		return state.userInfo;
	},

	[AuthGetter.USERNAME](state: IAuthState) {
		return state.userInfo !== null ? state.userInfo.Name : null;
	},

	[AuthGetter.IMAGE_ID](state: IAuthState) {
		return state.userInfo !== null ? state.userInfo.ImageId : null;
	},

	[AuthGetter.IS_ADMIN](state: IAuthState): boolean {
		return (state.userInfo?.Type == UserType.Administrador ||  state.userInfo?.Type == UserType.AdminMaster) ?? false;
	},

	[AuthGetter.IS_ADMIN_MASTER](state: IAuthState): boolean {
		return state.userInfo?.Type == UserType.AdminMaster ?? false;
	},

	[AuthGetter.IS_GESTOR](state: IAuthState): boolean {
		return state.userInfo?.Type == UserType.Gestor ?? false;
	},

	[AuthGetter.USER_TYPE](state: IAuthState) {
		return state.userInfo?.Type ?? null;
	},
	
	[AuthGetter.HAS_TASKS_MODULE](state: IAuthState) {
		return state.userInfo !== null ? state.userInfo.Modules.includes(tasksModuleId) : null;
	},

	[AuthGetter.HAS_REGISTERS_MODULE](state: IAuthState) {
		return state.userInfo !== null ? state.userInfo.Modules.includes(registersModuleId) : null;
	},

	[AuthGetter.IS_DARK_MODE](state: IAuthState) {
		// return state.userInfo?.DarkMode ?? true;
		if (state.isDarkMode === null)
			state.isDarkMode = JSON.parse(localStorage.getItem("isDarkMode") ?? "false") as boolean;

		return state.isDarkMode;
	},

	[AuthGetter.IS_LOGGED_IN](state: IAuthState) {
		return state.userInfo !== null;
	},
};