import { useStore } from "vuex";
import { AuthGetter } from "@/store/modules/auth/getters";

export class User {
	name = "";
	email = "";
	type = "";
	id = "";
	imageSrc = "";
}


export enum JourneyType {
	Period = 1,
	Hours = 2,
}

export enum UserType {
	Colaborador = 1,
	Administrador = 2,
	Gestor = 3,
	AdminOwntec = 4,
}

export const UserTypesText = [
	{
		type: UserType.Colaborador,
		text: "Colaborador",
	},
	{
		type: UserType.Administrador,
		text: "Administrador",
	},
	{
		type: UserType.Gestor,
		text: "Gestor",
	},
	{
		type: UserType.AdminOwntec,
		text: "Administrador Owntec",
	},
];

export function getUserTypeOptions() {

	const store = useStore();
	const isAdminMaster = store.getters[AuthGetter.IS_ADMIN_MASTER];

	if (isAdminMaster)
		return UserTypesText;
	else
		return UserTypesText.filter(userType => userType.type !== UserType.AdminOwntec);
}