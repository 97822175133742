<template>
	<div id="notification">
		<!--<button class="btn btn-small btn-secondary" id="open-notification" :class="hasNewNotification ? 'new-notification' : ''" @click="changeStateNotification" aria-label="Abrir notificações">-->
		<button class="btn btn-small btn-secondary" id="open-notification" @click="changeStateNotification" aria-label="Abrir notificações">
			<span class="how-many" v-if="howManyNewNotifications > 0">
				<template v-if="howManyNewNotifications <= 999">
					{{ howManyNewNotifications }}
				</template>
				<template v-else>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: currentColor">
						<path d="M17 7c-2.094 0-3.611 1.567-5.001 3.346C10.609 8.567 9.093 7 7 7c-2.757 0-5 2.243-5 5a4.98 4.98 0 0 0 1.459 3.534A4.956 4.956 0 0 0 6.99 17h.012c2.089-.005 3.605-1.572 4.996-3.351C13.389 15.431 14.906 17 17 17c2.757 0 5-2.243 5-5s-2.243-5-5-5zM6.998 15l-.008 1v-1c-.799 0-1.55-.312-2.114-.878A3.004 3.004 0 0 1 7 9c1.33 0 2.56 1.438 3.746 2.998C9.558 13.557 8.328 14.997 6.998 15zM17 15c-1.33 0-2.561-1.44-3.749-3.002C14.438 10.438 15.668 9 17 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"></path>
					</svg>
				</template>
			</span>
			<OwnIcon type="notification-2" />
		</button>

		<aside class="notifications" :class="isNotificationOpen ? 'open' : ''">
			<header>
				<h1>Notificações</h1>
				<button class="btn-close" @click="changeStateNotification"></button>
			</header>
			<span class="mark-read" @click="readAllNotifications">marcar todas como lidas</span>
			<div v-if="isLoading">
				<span class="loading-notification">
					<span class="spinner-border spinner-border-sm" role="status"></span>
					Carregando...
				</span>
			</div>
			<template v-for="(notification, idx) in notifications" :key="idx">
				<router-link v-if="notification.Link !== '' && !notification.IsTask" :to="notification.Link" class="notification-container link" :class="notification.HasReaded ? '' : 'not-readed'" @click="readNotification(notification)">
					<i class="icons" :class="notification.Icon"></i>
					<section class="contents">
						<section class="notification-header">
							<h2 class="title">{{ notification.Title }}</h2>
							<span class="time">{{ notification.Time }}</span>
						</section>
						<p class="description">{{ notification.Description }}</p>
					</section>
				</router-link>

				<div v-else-if="notification.IsTask" class="notification-container link" :class="notification.HasReaded ? '' : 'not-readed'" @click="readNotification(notification)">
					<i class="icons" :class="notification.Icon"></i>
					<section class="contents">
						<section class="notification-header">
							<h5 class="title">{{ notification.Title }}</h5>
							<span class="time">{{ notification.Time }}</span>
						</section>
						<p class="description">{{ notification.Description }}</p>
					</section>
				</div>

				<div v-else class="notification-container" :class="notification.HasReaded ? '' : 'not-readed'" @click="readNotification(notification)">
					<i class="icons" :class="notification.Icon"></i>
					<section class="contents">
						<section class="notification-header">
							<h5 class="title">{{ notification.Title }}</h5>
							<span class="time">{{ notification.Time }}</span>
						</section>
						<p class="description">{{ notification.Description }}</p>
					</section>
				</div>
			</template>
			<button v-if="totalNotifications - 10 >= 10" class="btn btn-secondary show-more" @click="loadNotifications({record: lastNotificationRecord})">
				<span v-if="isLoading" class="loading-notification">
					<span class="spinner-border spinner-border-sm" role="status"></span>
					Carregando...
				</span>
				<span v-else>Mostrar mais</span>
			</button>
		</aside>
	</div>
</template>

<script setup lang="ts">
import { onBeforeMount, onUnmounted, ref } from "vue";
import { Notification, notificationTypes } from "@/models/Components/Notification";
import { notify } from "@kyvg/vue3-notification";
import { DefaultError } from "@/utils/general";
import GetAllNotificationsRequest from "@/api/requests/notifications/GetAllNotificationsRequest";
import moment from "moment";
import ReadNotificationRequest from "@/api/requests/notifications/ReadNotificationRequest";
import ReadAllNotificationsRequest from "@/api/requests/notifications/ReadAllNotificationsRequest";
import HasNotificationRequest from "@/api/requests/notifications/HasNotificationRequest";
import router from "@/router";
import OwnIcon from "@/components/OwnIcon.vue";


const isNotificationOpen = ref(false);
const notifications = ref<Notification[]>([]);
const totalNotifications = ref(0);
const hasNewNotification = ref(false);
const howManyNewNotifications = ref(0);
let date = moment();
let lastNotificationRecord = ref(0);
const isLoading = ref(false);

/**
 * changeStateNotification = abre e fecha a aba de notificações
 */
const changeStateNotification = () => {
	isNotificationOpen.value = !isNotificationOpen.value;
	if (isNotificationOpen.value) {
		loadNotifications({clearNotification: true});
	}
};

/**
 * Solicita à API o carregamento de 10 notificações.
 * @param record o registro o qual devem ser carregadas notificações (anteriores)
 * @param clearNotification se deve limpar a lista original de notificações carregadas
 */
const loadNotifications = async ({
	                                 record = 0,
	                                 clearNotification = false,
                                 }) => {
	try {
		isLoading.value = true;

		date = moment();

		const request = new GetAllNotificationsRequest(record);
		const response = await request.execute();

		totalNotifications.value = 0;//apenas se for lista
		if (clearNotification)
			notifications.value = [];

		if (!response.Success) {
			notify(DefaultError(response.Errors[0].Message));
			console.error(response.Errors[0].Message);
		}
		else {
			for (const NotificationInfo of response.Result.Records) {
				let notification = new Notification();
				notification.Record = NotificationInfo.Record;
				notification.NotificationId = NotificationInfo.NotificationId;
				notification.GenericId = NotificationInfo.GenericId ?? "";
				notification.Type = NotificationInfo.Type;
				notification.Description = NotificationInfo.Description;
				notification.HasReaded = NotificationInfo.IsReaded;
				notification.Time = moment(NotificationInfo.Time).isSame(date, "day") ?
					moment(NotificationInfo.Time).locale("pt-br").format("LT") :
					moment(NotificationInfo.Time).locale("pt-br").format("DD MMM");
				lastNotificationRecord.value = NotificationInfo.Record;

				let notificationInfo = notificationTypes[notification.Type] ?? notificationTypes[0];

				notification.Title = notificationInfo.title;
				notification.Icon = notificationInfo.icon;
				notification.IsTask = notificationInfo.isTask;

				if (notification.IsTask)
					notification.Link = NotificationInfo.GenericId;
				else if (notificationInfo.path == "")
					notification.Link = "";
				else if (notificationInfo.hasDirectLink)
					notification.Link = `/${notificationInfo.path}${notification.GenericId}`;
				else
					notification.Link = `/${notificationInfo.path}`;

				notifications.value.push(notification);
			}
			totalNotifications.value = response.Result.TotalRecords;
		}
	}
	catch (err) {
		console.warn(err);
	}
	finally {
		isLoading.value = false;
	}
};

/**
 * Solicita à API a leitura de uma notificação
 * @param notification a notificação a ser lida
 */
const readNotification = async (notification: Notification) => {
	try {
		notification.HasReaded = true;
		const request = new ReadNotificationRequest();
		request.NotificationId = notification.NotificationId;

		if (notification.IsTask)
			await router.push({
				query: {
					taskIdFromQuery: notification.GenericId,
				},
			});

		await request.execute();

	}
	catch (err) {
		console.warn(err);
	}
	finally {
		await verifyNewNotifications();
	}
};

/**
 * Solicita à API a leitura de todas as notificações do usuário
 */
const readAllNotifications = async () => {
	try {
		for (const notification of notifications.value) {
			notification.HasReaded = true;
		}
		howManyNewNotifications.value = 0;
		const request = new ReadAllNotificationsRequest();
		await request.execute();

	}
	catch (err) {
		console.warn(err);
	}
	finally {
		await verifyNewNotifications();
	}
};

/**
 * Solicita à API a verificação se há mais notificaçoes não lidas
 */
const verifyNewNotifications = async () => {
	try {
		const request = new HasNotificationRequest();
		const response = await request.execute();
		hasNewNotification.value = response.HasNotification;
		howManyNewNotifications.value = response.TotalNotificationNotReaded;
	}
	catch (err) {
		console.warn(err);
	}
};

let intervalRequestNotifications = ref<number>(0);

onBeforeMount(async () => {
	await verifyNewNotifications();

	intervalRequestNotifications.value = setInterval(async () => {
		await verifyNewNotifications();

	}, 60000);
});

onUnmounted(() => {
	clearInterval(intervalRequestNotifications.value);
});


</script>
<style lang="scss">
@import "@/styles/Variables.scss";

div#notification {
	position: relative;

	.how-many {
		min-width: 1.2rem;
		font-size: 0.85rem;
		padding: 0.1rem 0.35rem;
		font-weight: 800;
		color: $ot-dark-text-primary;
		border-radius: 1rem;
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
		background-color: $ot-danger
	}

	header {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		margin-top: 3rem;
		margin-left: 1.5rem;
		color: $ot-highlight-title;

		h1 {
			font-size: 1.75rem;
			font-weight: 600;
		}

		.btn-close {
			margin-right: 1rem
		}
	}

	.mark-read {
		font-size: 0.85rem;
		color: $ot-highlight-title;
		align-self: flex-end;
		cursor: pointer;
		margin: 1rem 0.5rem;

		&:hover {
			color: $ot-highlight-active;
		}
	}

	.notifications {
		position: fixed;
		right: 0;
		top: 0;
		z-index: 10;
		width: 19rem;
		height: 100vh;
		background-color: $ot-background-secondary-full;
		color: $ot-text-tertiary;
		overflow-x: auto;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		//box-shadow: -8px 0px 58px -37px rgb(0 0 0 / 50%);
		border-left: 1px solid hsl(210deg 35% 91%);

		&:not(.open) {
			display: none;
		}
	}

	.notification-container {
		display: flex;
		border-bottom: 2px solid hsl(210deg 35% 91%);
		gap: 0.75rem;
		align-items: center;
		padding: 1rem;

		&.link {
			text-decoration: none;
			color: $ot-text-secondary;
			cursor: pointer;
		}

		&:hover {
			background-color: $ot-dark-background-secondary;
		}

		.icons {
			font-size: 1.75rem;
			margin-top: 0.75rem;
			margin-bottom: 0.75rem;
			margin-right: 0.5rem;
			color: $ot-text-tertiary;
		}

		.contents {
			width: 100%;

			.notification-header {
				display: flex;
				justify-content: space-between;

				.title {
					font-size: 1.15rem;
				}

				.title,
				.time {
					margin-bottom: 0.2rem;
					color: $ot-text-tertiary;
				}
			}

			.description {
				color: $ot-text-tertiary;
				margin-bottom: 0;
			}
		}

		&.not-readed {
			.icons {
				color: $ot-highlight-title;
			}

			.notification-header {
				.title,
				.time {
					font-weight: 600;
					color: $ot-highlight-title;
				}
			}

			.description {
				color: $ot-highlight-title;
			}
		}
	}

	.loading-notification {
		font-weight: 600;
		margin-top: 2rem;
		margin-left: 1rem;
	}


	.show-more {
		margin-top: 1rem;
		margin-inline: auto;
		margin-bottom: 1rem;
	}
}

body:has(#app > main.dark) {
	div#notification {
		header {
			color: $ot-dark-text-primary;
		}

		.mark-read {
			color: $ot-dark-text-primary;

			&:hover {
				color: $ot-highlight-active;
			}
		}

		.notifications {
			background-color: $ot-dark-background-secondary-full;
			color: $ot-dark-text-secondary;
			border-left: 1px solid $ot-dark-background-primary;

			&:not(.open) {
				display: none;
			}
		}

		.notification-container {
			border-bottom: 2px solid $ot-dark-background-primary;

			&.link {
				text-decoration: none;
				color: $ot-dark-text-secondary;
			}

			&:hover {
				background-color: $ot-dark-background-secondary;
			}

			.icons {
				color: $ot-dark-text-tertiary;
			}

			.contents {
				* {
					color: $ot-dark-text-tertiary
				}
			}

			&.not-readed {
				.icons {
					color: $ot-dark-text-primary;
				}

				.notification-header {
					.title {
						color: $ot-dark-text-primary;
					}
				}

				.contents {
					* {
						color: $ot-dark-text-primary
					}
				}
			}
		}

		.loading-notification {
			color: $ot-highlight-active;
		}
	}

	.mark-read {
		color: $ot-dark-text-secondary;

		&:hover {
			color: $ot-dark-text-primary;
		}
	}
}
</style>