<script setup lang="ts">

import { FooterOptions, ToolbarOptions } from "@/utils/markdown-editor-config";
import { MdEditor, MdPreview } from "md-editor-v3";
import { computed, defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { AuthGetter } from "@/store/modules/auth/getters";
import store from "@/store";

const props = defineProps({
	text: {
		type: String,
		required: true,
	},
	preview: {
		type: Boolean,
		default: false,
	},
	inputLimit: {
		type: Number,
		default: 2048,
	},
});


const emit = defineEmits([
	"text-updated",
]);

const isDarkMode = computed(() => store.getters[AuthGetter.IS_DARK_MODE]);
const usingMarkdown = ref(false);

const text = computed({
	get() {
		return props.text;
	},
	set(newValue: string) {
		emit("text-updated", newValue);
	},
});

/**
 * Muda o visualizador/editor de descrição Markdown
 */
watch(usingMarkdown, () => {
	localStorage.setItem("usingMarkdown", JSON.stringify(usingMarkdown.value));
});

onMounted(() => {
	usingMarkdown.value = JSON.parse(localStorage.getItem("usingMarkdown") ?? "false") as boolean;
})

</script>

<template>
	<MdPreview v-if="props.preview" class="form-control"
	           :theme="isDarkMode ? 'dark' : 'light'"
	           v-model="text"
	           language="pt-BR" />

	<div class="text-area-container" v-else>
		<textarea v-if="!usingMarkdown"
		          id="description"
		          class="form-control text-area"
		          name="description" cols="10" rows="8"
		          v-model="text"
		></textarea>
		<MdEditor v-else class="form-control"
		          v-model="text"
		          :theme="isDarkMode ? 'dark' : 'light'"
		          language="pt-BR"
		          :toolbars="ToolbarOptions"
		          :footers="FooterOptions"
		          no-upload-img
		/>
		<footer class="text-area-footer">
			<div class="form-check form-switch">
				<input class="form-check-input" type="checkbox" role="switch" id="checklistMarkdown" v-model="usingMarkdown">
				<label class="form-check-label" for="checklistMarkdown">Markdown</label>
			</div>
			<span class="characters" :class="{'over-limit': text?.length >= props.inputLimit}">
				{{ text?.length }}/2048
			</span>
		</footer>
	</div>
</template>

<style lang="scss">
@import "md-editor-v3/lib/style.css";
@import "@/styles/Variables.scss";

.md-editor {
	display: flex!important;
	max-height: 20rem;
	min-height: 4rem;
	--md-color: hsla(210, 12%, 29%, 1);
	--md-bk-color: hsla(0, 0%, 100%, 1);
	font-family: "Nunito", sans-serif;

	border-radius: 1rem!important;
	padding: 0!important;

	&:active {
		box-shadow: none!important;
	}

	.md-editor-preview-wrapper * {
		word-break: normal;
	}

	.md-editor-preview {
		--md-theme-link-color: hsla(142, 50%, 52%, 1);
		--md-theme-link-hover-color: hsla(142, 61%, 64%, 1);
	}
}

.md-editor-dark {
	--md-color: hsla(218, 36%, 96%, 1);
	--md-bk-color: hsla(222, 17%, 19%, 1);
}

.text-area-footer {
	display: flex;
	justify-content: space-between;
	
	.characters {
		display: block;
		text-align: end;
		font-size: .8rem;
		opacity: .8;
	}
}

.form-control {
	&.text-area {
		margin: 0.5rem 0;
	}
}

.md-editor {
	margin: 0.5rem 0;
}

</style>