<template>
	<Teleport to="body">
		<dialog class="modal-background" ref="modal" tabindex="-1" :id="props.id" :aria-labelledby="props.id + 'Label'" aria-hidden="true" @keydown="blockEsc">
			<section class="modal-content" :class="props.modalSize">
				<div class="modal-header" v-if="!props.noHeader">
					<h5 class="modal-title" :id="props.id + 'Label'">
						<slot name="header" />
					</h5>
					<button class="btn-close" @click="close">
						<span hidden aria-hidden="true">Fechar</span>
					</button>
				</div>

				<div class="modal-body" v-if="!props.noBody">
					<slot name="body" />
				</div>

				<div class="modal-footer" v-if="!props.noFooter">
					<slot name="footer" />
				</div>
			</section>
		</dialog>
	</Teleport>
</template>

<script setup lang="ts">

import { PropType, ref, defineProps, defineExpose } from "vue";
import router from "@/router";

type modalSize =
	"modal-sm"
	| "modal-lg"
	| "modal-xl";

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	noHeader: {
		type: Boolean,
		default: false,
	},
	noBody: {
		type: Boolean,
		default: false,
	},
	noFooter: {
		type: Boolean,
		default: false,
	},
	modalSize: {
		type: String as PropType<modalSize>,
	},
	blockEscape: {
		type: Boolean,
		default: false,
	},
});

const modal = ref<HTMLDialogElement>();

function blockEsc(e: KeyboardEvent) {
	if (e.key === "Escape" && props.blockEscape) {
		e.preventDefault();
	}
	else if (e.key === "Escape") {
		close();
	}
}

function open() {
	modal.value?.showModal();
}

async function close() {
	await router.replace({
		query: undefined,
	});
	modal.value?.close();
}

defineExpose({
	open,
	close,
});

</script>

<style scoped lang="scss">

@import "@/styles/Variables.scss";

dialog::backdrop {
	background-color: hsla(0, 0%, 0%, 0.6);
}

.modal-background {

	width: 100%;
	height: 100%;
	background: none;
	border: none;
	transition: transform 0.3s ease-out, opacity 0.2s linear;
	transform: translateY(-50px);
	opacity: 0;
	max-width: unset;
	display: block;
	pointer-events: none;
	max-height: unset;
	position: absolute;
	top: 0;
	scrollbar-gutter: stable;

	&:focus-visible {
		outline: none;
	}

	&[open] {

		pointer-events: all;
		opacity: 1;
		transform: translateY(0);
		position: unset;

		.modal-content {
			pointer-events: all;
		}
	}

	.modal-content {
		max-width: 500px;
		width: 100%;
		border: none;
		background: #fff;
		margin: 3dvh auto;
		box-shadow: rgba(0, 0, 0, .1) 0 1px 15px;
		flex-direction: column;
		border-radius: 20px;
		display: flex;
		pointer-events: none;
		top: 0;


		.modal-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1rem;
			border: none;
		}

		.modal-body {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding: 0.5rem 1.5rem 1rem;
		}

		.modal-footer {
			border: none;
			padding: 0 0.75rem 0.75rem;

			@media(max-width: 768px) {
				flex-direction: column;
				align-items: stretch;
			}
		}

		&.modal-sm {
			max-width: 300px;
		}

		&.modal-lg {
			max-width: 800px;
		}

		&.modal-xl {
			max-width: 1140px;
		}

		.pagination-control {
			margin-bottom: 0 !important;
		}

		@media(max-width: 575px) {
			width: 95%;
			margin: 1dvh auto;
		}
	}

}

</style>