import { Module } from "vuex";
import { IRootState } from "@/store";
import { IAuthState, state } from "./state";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

// Module
const auth: Module<IAuthState, IRootState> = {
    state,
    getters,
    mutations,
    actions,
};

export default auth;