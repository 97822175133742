import { RouteRecordRaw } from "vue-router";
import RouteName from "@/router/routes-name";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		props: true,
		name: RouteName.HOME,
		meta: {
			registersModuleOnly: true,
			title: "Geral",
		},
		component: () => import(/* webpackChunkName: "general" */ "@/views/General/HomeView.vue"),
	},
	{
		path: "/atividades",
		name: RouteName.ACTIVITIES,
		meta: {
			tasksModuleOnly: true,
			title: "Atividades",
		},
		component: () => import(/* webpackChunkName: "general" */ "@/views/General/ActivitiesView.vue"),
	},
	{
		path: "/sprints",
		name: RouteName.SPRINTS,
		meta: {
			tasksModuleOnly: true,
			title: "Sprints",
		},
		component: () => import(/* webpackChunkName: "general" */ "@/views/General/SprintsView.vue"),
	},
	{
		path: "/novidades",
		props: true,
		name: RouteName.VERSION_NEWS,
		meta: {
			title: "Novidades da versão",
		},
		component: () => import(/* webpackChunkName: "general" */ "@/views/General/VersionNews.vue"),
	},
];

export default routes;