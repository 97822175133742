import { RouteRecordRaw } from "vue-router";
import RouteName from "@/router/routes-name";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/login",
		name: RouteName.LOGIN,
		meta: {
			allowAnonymous: true,
			layout: "empty"
		},
		component: () => import(/* webpackChunkName: "account" */ "@/views/Account/Login.vue"),
	},
	{
		path: "/logout",
		name: RouteName.LOGOUT,
		component: () => import(/* webpackChunkName: "account" */ "@/views/Account/Logout.vue"),
	},
	{
		path: "/minha-conta",
		name: RouteName.MY_ACCOUNT,
		meta: {
			title: "Minha conta",
		},
		component: () => import(/* webpackChunkName: "account" */ "@/views/Account/MyAccount.vue"),
	},
	{
		path: "/nova-senha/:token",
		name: RouteName.NEW_PASSWORD,
		meta: {
			allowAnonymous: true,
			layout: "empty",
		},
		props: true,
		component: () => import(/* webpackChunkName: "account" */ "@/views/Account/NewPassword.vue"),
	},
];

export default routes;