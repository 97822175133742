import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetUserHoursTaskRecordedTime {

	TaskId = "";

	constructor(id: string) {
		this.TaskId = id;
	}

	async execute(): Promise<DefaultListResponse<TaskRecordedUserHours>> {
		const response = await Api.get("/task-recorded-time/user-hours", {
			params: this,
		});
		return response.data;
	}
}

interface TaskRecordedUserHours {
	ImageId: string,
	Name: string,
	TotalUserHours: string,
}