import Api from "@/api";
import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";

export default class GetTaskRecordedTimeRequest {
	
	TaskId = "";

	constructor(id: string) {
		this.TaskId = id;
	}

	async execute(): Promise<DefaultListResponse<TaskRecordedTime>> {
		const response = await Api.get("/task-recorded-time", {
			params: this,
		});
		return response.data;
	}
}

interface TaskRecordedTime {
	TaskRecordedTimeId: string,
	Name: string,
	Date: string
	Time: string,
	ImageId: string,
	CanDelete: boolean,
}