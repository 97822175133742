<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "EmptyLayout",
});
</script>

<template>
	<main class="mty-layout">
		<router-view />
		<notifications position="bottom right" :duration="5000" :speed="200" />
	</main>
</template>

<style lang="scss">
main.mty-layout {
	
}
</style>