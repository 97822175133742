import { StageTypes } from "@/api/requests/task/GetTaskRequest";
import Api from "@/api";

/**
 * Interrupção de alguma função em um tempo definido em milissegundos
 * @param millis tempo em milissegundos a ser esperado
 * @return Promise<void> espera a execução da função setTimeout para prosseguir a execução do código
 */
export const wait = (millis: number): Promise<void> => {
	return new Promise<void>((resolve) => setTimeout(resolve, millis));
};

export enum StateBank {
	ZERO = "",
	POSITIVE = "positive",
	NEGATIVE = "negative",
}

// Notifications ------
export const NotificationType = {
	SUCCESS: "success",
	WARN: "warn",
	ERROR: "error",
	INFO: "",
};

export const DefaultWarn = {
	type: NotificationType.WARN,
	title: "Aviso",
	text: "Falha ao realizar a solicitação. Contate o administrador.",
};

export const DefaultError = (errorMessage: string | null) => {
	return {
		type: NotificationType.ERROR,
		title: "Erro",
		text: errorMessage ?? "Ocorreu um erro",
	};
};

export function checkGUID(guid: string){
	const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
	
	return pattern.test(guid);
}

/**
 * Verificação de banco de horas positivo ou negativo a partir de uma string de horas no padrão HH:MM
 * @param hours string no padrão HH:MM
 * @param hasPositive se a função deve retornar a classe "Positiva"
 * @return StateBank -> enumerador de classes de Banco de Horas, com possibilidades: Zero, Positive e Negative.
 */
export const strHoursToClass = (hours: string, hasPositive = true): StateBank => {
	const splittedHours = hours.split(":");

	if (hours === "")
		return StateBank.ZERO;

	if (splittedHours.length != 2) {
		console.error("O valor informado não é válido");
		return StateBank.ZERO;
	}

	const isNegative = hours.includes("-");

	const hourData = parseInt(splittedHours[0].replaceAll(" ", ""));
	const minuteData = parseInt(splittedHours[1].replaceAll(" ", ""));

	if (!isNegative && (hourData == 0 && minuteData > 0) || (hourData > 0 && minuteData >= 0))
		return hasPositive ? StateBank.POSITIVE : StateBank.ZERO;
	else if ((hourData == 0 && minuteData == 0))
		return StateBank.ZERO;
	else
		return StateBank.NEGATIVE;
};


export const getHoursAndMinutes = (time: string) => {
	const [hours, minutes] = time.split(":");
	return {
		hours,
		minutes,
	};
};

// "10:05" => 605
export const convertTimeTextToMinutes = (time: string): number => {
	const timeParts = time.split(":");
	let isNegative = false;
	if (timeParts.length !== 2)
		return 0;

	let hoursInt = parseInt(timeParts[0]) | 0;
	const minutesInt = parseInt(timeParts[1]) | 0;

	if (hoursInt < 0) {
		hoursInt *= -1;
		isNegative = true;
	}

	let result = hoursInt * 60 + minutesInt;

	if (isNegative)
		result *= -1;
	return result;
};

// 605 => "10:05"
export const convertMinutesToTimeText = (totalMinutes: number): string => {

	const isNegative = totalMinutes < 0;
	let hours = isNegative ? Math.ceil(totalMinutes / 60) : Math.floor(totalMinutes / 60); // 44 0
	let minutes = totalMinutes % 60;

	let prefix = "";

	if (hours < 0 || minutes < 0) {
		hours = hours * -1;
		minutes = minutes * -1;
		prefix = "-";
	}

	return `${prefix}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};

export const convertObjectJourneyToText = (arrayJourney: object): string => {

	const arrayTextJourney = [];
	const array = Object.values(arrayJourney);

	for (let i = 0; i < array.length; i++) {
		const day = array[i];
		let currentHour = "";
		let startDate = "";
		let finalDate = "";

		if (day.value == "00:00:00" || day.value == "00:00")
			continue;

		if (startDate == "") {
			startDate = day.text;
			currentHour = day.value;
		}

		for (let j = i; j < array.length; j++) {
			const otherDay = array[j];

			if ((startDate == otherDay.text || currentHour == otherDay.value) && j != array.length - 1)
				continue;

			if (finalDate == "") {
				if (startDate != otherDay.text) {
					finalDate = j == array.length - 1 ? array[j].text : array[j - 1].text;

					if (startDate == finalDate)
						arrayTextJourney.push(`${startDate}: ${currentHour}`);

					else
						arrayTextJourney.push(`${startDate}-${finalDate}: ${currentHour}`);

				}

				if (j > i) {
					i = j - 1;
					break;
				}
			}
		}
	}

	return arrayTextJourney.join(", ");
};

export function getStageTypeText(type: StageTypes) {
	switch (type) {
		case StageTypes.Backlog:
			return "Backlog";
		case StageTypes.AFazer:
			return "A fazer";
		case StageTypes.Fazendo:
			return "Fazendo";
		case StageTypes.Verificar:
			return "Verificar";
		case StageTypes.Feito:
			return "Feito";
	}
}

export function getImageURL(imageId: string, size = 64) {
	return `${Api.defaults.baseURL}/account/image/${imageId}/${size}`;
}