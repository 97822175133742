export class TaskRecordedTime {
	Id = "";
	Name = "";
	Date = "";
	Time = "";
	ImageUrl = "";
	CanDelete = false;
	TeamName = "";
	ProjectName = "";
	ProjectColor = "";
	Stage = ""
}


export class TaskRecordedTimeAdmin extends TaskRecordedTime {
	TaskName = "";
	TaskId = "";
}