import Api from "@/api";
import { HoursJourney, PeriodJourney } from "@/api/requests/account/GetAccountRequest";
import { JourneyType } from "@/models/Admin/Users";

export default class GetUserWorkJourney {

	async execute(): Promise<UserJourney> {
		const response = await Api.get("/gerenciamento/usuarios/user-work-journey");
		return response.data;
	}
}

interface UserJourney {
	JourneyType: JourneyType,
	HoursJourney: HoursJourney,
	PeriodJourney: PeriodJourney,
	StartDate: string
}

