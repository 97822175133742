import Api from "@/api";
import { DefaultBooleanResponseResult } from "@/api/responses/generic/DefaultBooleanResponse";
import { AbsenceType } from "@/api/requests/absenses/GetAbsencesRequest";

export default class CreateAbsenceSelfRequest {
	File: File[] | null = null;
	Message = "";
	Type: AbsenceType = 0;
	BeginDate = "";
	EndDate = "";


	async execute(): Promise<DefaultBooleanResponseResult<string>> {
		const formData = new FormData();
		formData.append("Message", this.Message);
		formData.append("BeginDate", this.BeginDate);
		formData.append("EndDate", this.EndDate);
		formData.append("Type", this.Type.toString());
		if (this.File !== null) {
			this.File.forEach(file => formData.append("File", file));
		}
		const response = await Api.post("/absence-justification/self", formData);
		return response.data;
	}
}