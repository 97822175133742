import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";
import Api from "@/api";

export default class CreateTaskLogCommentRequest {
	TaskLogId = "";
	Commentary = "";

	async execute(): Promise<CommentaryResponse> {
		const response = await Api.post("/task-log/commentary", this);
		return response.data;
	}
}

interface CommentaryResponse extends DefaultBooleanResponse {
	Record: number;
} 