import { RouteRecordRaw } from "vue-router";
import RouteName from "@/router/routes-name";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/dashboard",
		name: RouteName.DASHBOARD,
		meta: {
			adminOnly: true,
			title: "Dashboard",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Dashboard/DashboardView.vue"),
	},
	{
		path: "/dashboard/gestao-visual",
		name: RouteName.VISUAL_MANAGEMENT,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Gestão visual",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Dashboard/VisualManagement.vue"),
	},
	{
		path: "/registros",
		name: RouteName.REGISTERS_ADMIN,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Registros de usuários",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Registers/RegistersAdmin.vue"),
	},
	{
		path: "/justificativas",
		name: RouteName.JUSTIFICATIONS_ADMIN,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Justificativas de usuários",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Justifications/JustificationsAdmin.vue"),
	},
	{
		path: "/usuarios/criar",
		name: RouteName.CREATE_USER,
		meta: {
			adminOnly: true,
			title: "Criar usuário",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Users/CreateUser.vue"),
	},
	{
		path: "/usuarios/:userId/editar",
		name: RouteName.EDIT_USER,
		meta: {
			adminOnly: true,
			title: "Editar usuário",
		},
		props: true, //o props permite o componente acessar os parametros da rota.
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Users/EditUser.vue"),
	},
	{
		path: "/usuarios/:userId",
		name: RouteName.VISUALIZE_USER,
		meta: {
			title: "Visualizar usuário",
		},
		props: true, //o props permite o componente acessar os parametros da rota.
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Users/VisualizeUser.vue"),
	},
	{
		path: "/usuarios",
		name: RouteName.USERS,
		meta: {
			title: "Usuários",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Users/Users.vue"),
	},
	{
		path: "/banco",
		name: RouteName.BANK_USERS,
		meta: {
			adminOnly: true,
			title: "Banco de horas de usuários",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Bank/BankUsers.vue"),
	},
	{
		path: "/ajustes",
		name: RouteName.ADJUSTMENTS_LIST,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Solicitações de ajustes",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Adjustments/AdjustmentsList.vue"),
	},
	{
		path: "/ajustes/avaliar/:CardRecordAdjustmentId",
		name: RouteName.APPRAISE_ADJUSTMENTS,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Avaliar solicitação de ajuste",
		},
		props: true, //o props permite o componente acessar os parametros da rota.
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Adjustments/AppraiseAdjustments.vue"),
	},
	{
		path: "/equipes",
		name: RouteName.TEAMS,
		meta: {
			title: "Equipes",
		},
		component: () => import/* webpackChunkName: "admin" */ ("@/views/Admin/Teams/Teams.vue"),
	},
	{
		path: "/equipes/criar",
		name: RouteName.CREATE_TEAM,
		meta: {
			adminOnly: true,
			title: "Criar equipe",
		},
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Teams/CreateTeam.vue"),
	},
	{
		path: "/equipes/:TeamId/editar",
		name: RouteName.EDIT_TEAM,
		meta: {
			adminOnly: true,
			title: "Editar equipe",
		},
		props: true, //o props permite o componente acessar os parametros da rota.
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Teams/EditTeam.vue"),
	},
	{
		path: "/equipes/:TeamId",
		name: RouteName.VISUALIZE_TEAM,
		meta: {
			title: "Ver equipe",
		},
		props: true,
		component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Teams/VisualizeTeam.vue"),
	},
	{
		path: "/feriados",
		name: RouteName.HOLIDAYS,
		meta: {
			registersModuleOnly: true,
			title: "Feriados",
		},
		component: () => import/* webpackChunkName: "admin" */ ("@/views/Admin/Holidays/HolidaysView.vue"),
	},
	{
		path: "/abonos",
		name: RouteName.ABSENCES,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Abonos de faltas",
		},
		component: () => import/* webpackChunkName: "admin" */ ("@/views/Admin/Absenses/AbsencesList.vue"),
	},
	{
		path: "/abonos/:AbsenceId",
		name: RouteName.VISUALIZE_ABSENCE,
		meta: {
			registersModuleOnly: true,
			adminOnly: true,
			title: "Avaliar solicitação de abono de falta",
		},
		props: true,
		component: () => import/* webpackChunkName: "admin" */ ("@/views/Admin/Absenses/AppraiseAbsence.vue"),
	},
	{
		path: "/configuracoes",
		name: RouteName.SETTINGS,
		meta: {
			adminOnly: true,
			title: "Configurações",
		},
		component: () => import/* webpackChunkName: "admin" */ ("@/views/Admin/Settings/SettingsView.vue"),
	},	
];

export default routes;