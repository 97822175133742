<template>
	<section id="register-container">
		<div class="register">
			<button v-if="todayHoursBank.LastRegister === CardRecordType.Saida" class="btn btn-primary"
			        @click="requestCreateCardRecord(CardRecordType.Entrada)" title="Registrar entrada">
				<OwnIcon type="play" fill large/>
			</button>
			<button v-else class="btn btn-danger" @click="requestCreateCardRecord(CardRecordType.Saida)"
			        title="Registrar saída">
				<OwnIcon type="stop" fill large/>
			</button>
			<p>{{ todayHoursBank.RegisteredTime }}/{{ todayHoursBank.WorkJourney }}</p>
			<p>{{ todayHoursBank.ExibitionText }}</p>
			<button class="btn btn-secondary btn-sm" @click="createActivity">
				<OwnIcon type="add"/>
				Nova atividade
			</button>
		</div>
	</section>
</template>

<script setup lang="ts">
import {onBeforeMount, onUnmounted, ref} from "vue";
import {CardRecordType} from "@/api/requests/card-record/GetUserCardsRecordsRequest";
import {loadStart, loadStop} from "@/utils/loading";
import CreateCardRecordRequest from "@/api/requests/card-record/CreateCardRecordRequest";
import {notify} from "@kyvg/vue3-notification";
import {convertTimeTextToMinutes, DefaultError, DefaultWarn, NotificationType, strHoursToClass} from "@/utils/general";
import {TodayHoursBank} from "@/models/Hours/TodayHoursBank";
import GetTodayHoursBankRequest from "@/api/requests/bank/GetTodayHoursBankRequest";
import router from "@/router";
import OwnIcon from "@/components/OwnIcon.vue";

const isSendingRequest = ref(false);
const todayHoursBank = ref<TodayHoursBank>(new TodayHoursBank());

function createActivity() {
	router.push({
		query: {
			newTask: 'true',
		},
	});
}

/**
 * Solicita à API a inclusão de um registro do tipo especificado
 * @param type tipo de registro (Entrada: 1 ou Saída: 2)
 */
const requestCreateCardRecord = async (type: CardRecordType) => {
	if (isSendingRequest.value) {
		return;
	}

	loadStart();
	isSendingRequest.value = true;

	try {
		const request = new CreateCardRecordRequest(type);
		const response = await request.execute();

		if (!response.Success) {
			notify(DefaultError(response.Errors[0].Message));
		} else {
			let hour = new Date().getHours();

			let workPhrase: string;
			let restPhrase = "Aproveite seu descanso!";

			if (hour < 12) {
				workPhrase = "Tenha uma boa manhã de trabalho!";
			} else if (hour >= 12 && hour < 19) {
				workPhrase = "Tenha uma boa tarde de trabalho!";
			} else {
				workPhrase = "Tenha uma boa noite de trabalho!";
			}

			if (hour >= 12 && hour <= 13) {
				restPhrase = "Um bom almoço para você!";
				if (new Date().getMinutes() == 0)
					restPhrase += " Tu gosta de pegar fila, hein?";
			}

			notify({
				type: NotificationType.SUCCESS,
				title: `Seu ponto de ${type == CardRecordType.Entrada ? "entrada" : "saída"} foi registrado`,
				text: type == CardRecordType.Entrada ? workPhrase : restPhrase,
			});
			todayHoursBank.value.LastRegister = type; //success
		}
	} catch (err) {
		notify(DefaultWarn);
	} finally {
		// Indicar que a requisição foi realizada
		isSendingRequest.value = false;
		loadStop();
	}
};

/**
 * Solicita à API os dados de Jornada diária, Horas trabalhadas e Banco de horas do dia.
 */
const requestTodayHoursBank = async () => {
	if (isSendingRequest.value) {
		return;
	}

	isSendingRequest.value = true;

	try {
		const request = new GetTodayHoursBankRequest();
		const response = await request.execute();

		todayHoursBank.value.HoursBank = response.Result.HoursBank;
		todayHoursBank.value.RegisteredTime = response.Result.RegisteredTime;
		todayHoursBank.value.WorkJourney = response.Result.WorkJourney;
		todayHoursBank.value.LastRegister = response.Result.LastRegister ?? CardRecordType.Saida;
		todayHoursBank.value.HoursBankClass = strHoursToClass(todayHoursBank.value.HoursBank);
		todayHoursBank.value.Percent = Math.round((convertTimeTextToMinutes(todayHoursBank.value.RegisteredTime) / convertTimeTextToMinutes(todayHoursBank.value.WorkJourney)) * 100);
		todayHoursBank.value.IsInVacation = response.Result.IsInVacation;

		if (Number.isNaN(todayHoursBank.value.Percent))
			todayHoursBank.value.Percent = 0;

		todayHoursBank.value.IsExtraDay = convertTimeTextToMinutes(todayHoursBank.value.WorkJourney) == 0 && convertTimeTextToMinutes(todayHoursBank.value.RegisteredTime) > 0;

		todayHoursBank.value.ExibitionText = todayHoursBank.value.IsInVacation ? "Férias" : todayHoursBank.value.IsExtraDay ? "Extra" : `${todayHoursBank.value.Percent}%`;

		if (!response.Success) {
			notify(DefaultError(response.Errors[0].Message));
		}
	} catch (err) {
		notify(DefaultWarn);
	} finally {
		// Indicar que a requisição foi realizada
		isSendingRequest.value = false;
	}
};

let intervalRequestTodayHours = ref<number>(0);

onBeforeMount(async () => {
	await requestTodayHoursBank();
	intervalRequestTodayHours.value = setInterval(async () => {
		await requestTodayHoursBank();
	}, 30000);
});

onUnmounted(() => {
	clearInterval(intervalRequestTodayHours.value);
});

</script>
<style lang="scss">
@import "@/styles/Variables.scss";

#register-container {
	position: fixed;
	right: -20rem;
	bottom: 5rem;
	transition: right ease-in-out 0.4s;
	z-index: 5;
	padding: 0.75rem 0 0.75rem 0.75rem;

	&:hover {
		right: 0;
	}

	.register {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		background-color: $ot-background-secondary-full;
		border: 1px solid hsl(210deg 35% 91%);
		border-radius: 15rem 0 0 15rem;


		.btn {
			min-width: unset;
		}

		p {
			margin: 0
		}

		> :last-child {
			margin-right: 1rem
		}

		@media (max-width: 425px) {
			display: none;
		}
	}
}

body:has(#app > main.dark) .register {
	background-color: $ot-dark-background-secondary-full;
	border: 1px solid $ot-dark-background-primary;
}
</style>