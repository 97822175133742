import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class CreateTagRequest {

	TeamId: string | null = "";
	Name = "";
	Color = "";

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.post("/tag", this);
		return response.data;
	}
}