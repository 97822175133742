import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class EditTagRequest {

	TagId = "";
	TeamId: string | null = "";
	Name = "";
	Color = "";

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.put("/tag", this);
		return response.data;
	}
}