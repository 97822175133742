import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetUsersInfoRequest {

	async execute(): Promise<DefaultListResponse<UsersInfo>> {
		const response = await Api.get("/gerenciamento/usuarios/users-info");
		return response.data;
	}
}

interface UsersInfo {
	Name: string,
	UserId: string,
	ImageId: string,
}