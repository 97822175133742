import Api from "@/api";
import { JourneyType } from "@/models/Admin/Users";

export default class GetAccountRequest {


	async execute(): Promise<AccountInfo> {
		const response = await Api.get("/account");
		return response.data;
	}

}

interface AccountInfo {
	Name: string,
	BirthDate: string | null,
	TimeZone: string,
	ImageId: string,
	JourneyType: JourneyType,
	HoursJourney: HoursJourney,
	PeriodJourney: PeriodJourney,
}

export class HoursJourney {
	Sunday = "";
	Monday = "";
	Tuesday = "";
	Wednesday = "";
	Thursday = "";
	Friday = "";
	Saturday = "";
}

export class PeriodJourney {
	Sunday = {
		Begin: "",
		End: "",
		BreakBegin: "",
		BreakEnd: "",
	};
	Monday = {
		Begin : "",
		End : "",
		BreakBegin : "",
		BreakEnd : "",
	};
	Tuesday = {
		Begin : "",
		End : "",
		BreakBegin : "",
		BreakEnd : "",
	};
	Wednesday = {
		Begin : "",
		End : "",
		BreakBegin : "",
		BreakEnd : "",
	};
	Thursday = {
		Begin : "",
		End : "",
		BreakBegin : "",
		BreakEnd : "",
	};
	Friday = {
		Begin : "",
		End : "",
		BreakBegin : "",
		BreakEnd : "",
	};
	Saturday = {
		Begin : "",
		End : "",
		BreakBegin : "",
		BreakEnd : "",
	};
}
