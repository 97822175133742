<template>
	<div id="journey">
		<ModalComponent id="myJourneyModal" ref="myJourneyModal" modal-size="modal-lg" no-footer>
			<template #header>
				Jornada de trabalho
			</template>

			<template #body>
				<div>
					<template v-for="(userJourney, idx) in userJourneys" :key="idx">
						<p>Início: {{ userJourney.startDate }}</p>
						<section v-if="userJourney.JourneyType === JourneyType.Period" class="table-container">
							<table class="table-general">
								<thead>
								<tr>
									<td></td>
									<th>Início jornada</th>
									<th>Inicio intervalo</th>
									<th>Fim intervalo</th>
									<th>Fim jornada</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<th>Segunda</th>
									<td>{{ userJourney.PeriodJourney.Monday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Monday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Monday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Monday.End }}</td>
								</tr>
								<tr>
									<th>Terça</th>
									<td>{{ userJourney.PeriodJourney.Tuesday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Tuesday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Tuesday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Tuesday.End }}</td>
								</tr>
								<tr>
									<th>Quarta</th>
									<td>{{ userJourney.PeriodJourney.Wednesday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Wednesday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Wednesday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Wednesday.End }}</td>
								</tr>
								<tr>
									<th>Quinta</th>
									<td>{{ userJourney.PeriodJourney.Thursday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Thursday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Thursday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Thursday.End }}</td>
								</tr>
								<tr>
									<th>Sexta</th>
									<td>{{ userJourney.PeriodJourney.Friday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Friday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Friday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Friday.End }}</td>
								</tr>
								<tr>
									<th>Sábado</th>
									<td>{{ userJourney.PeriodJourney.Saturday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Saturday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Saturday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Saturday.End }}</td>
								</tr>
								<tr>
									<th>Domingo</th>
									<td>{{ userJourney.PeriodJourney.Sunday.Begin }}</td>
									<td>{{ userJourney.PeriodJourney.Sunday.BreakBegin }}</td>
									<td>{{ userJourney.PeriodJourney.Sunday.BreakEnd }}</td>
									<td>{{ userJourney.PeriodJourney.Sunday.End }}</td>
								</tr>
								</tbody>
							</table>
						</section>
						<section v-else class="table-container">
							<table class="table-general">
								<tbody>
								<tr>
									<th>Segunda</th>
									<td>{{ userJourney.HoursJourney.Monday }}</td>
								</tr>
								<tr>
									<th>Terça</th>
									<td>{{ userJourney.HoursJourney.Tuesday }}</td>
								</tr>
								<tr>
									<th>Quarta</th>
									<td>{{ userJourney.HoursJourney.Wednesday }}</td>
								</tr>
								<tr>
									<th>Quinta</th>
									<td>{{ userJourney.HoursJourney.Thursday }}</td>
								</tr>
								<tr>
									<th>Sexta</th>
									<td>{{ userJourney.HoursJourney.Friday }}</td>
								</tr>
								<tr>
									<th>Sábado</th>
									<td>{{ userJourney.HoursJourney.Saturday }}</td>
								</tr>
								<tr>
									<th>Domingo</th>
									<td>{{ userJourney.HoursJourney.Sunday }}</td>
								</tr>
								</tbody>
							</table>
						</section>
					</template>
					<button class="btn btn-secondary" @click="loadJourney(true)" v-if="userJourneys.length === 1">
						Carregar jornadas anteriores
					</button>
				</div>
			</template>
		</ModalComponent>
	</div>
</template>

<script setup lang="ts">

import { onMounted, ref, defineExpose } from "vue";
import GetUserWorkJourney from "@/api/requests/users/GetUserWorkJourney";
import { JourneyType } from "@/models/Admin/Users";
import { notify } from "@kyvg/vue3-notification";
import { DefaultError, DefaultWarn } from "@/utils/general";
import { Journey } from "@/models/Account/Journey";
import { GetUserPreviousWorkJourney } from "@/api/requests/users/GetUserPreviousWorkJourney";
import { loadStart, loadStop } from "@/utils/loading";
import moment from "moment";
import ModalComponent from "@/components/ModalComponent.vue";

const userJourneys = ref<Journey[]>([]);
const isLoading = ref(false);

// Variaveis do modal
const myJourneyModal = ref<InstanceType<typeof ModalComponent>>();

function openJourneyModal() {
	myJourneyModal.value.open();
}

/**
 * Carrega a jornada do usuário
 * @param loadPrevious indica se serão carregadas jornadas anteriores à configurada atualmente
 */
const loadJourney = async (loadPrevious = false) => {

	if (isLoading.value)
		return;

	isLoading.value = true;

	try {
		if (!loadPrevious) {
			const request = new GetUserWorkJourney();
			const response = await request.execute();

			let userJourney = new Journey();

			userJourney.JourneyType = response.JourneyType;
			userJourney.HoursJourney = response.HoursJourney;
			userJourney.startDate = moment(response.StartDate).format("DD/MM/YYYY");

			if (userJourney.JourneyType === JourneyType.Period)
				userJourney.PeriodJourney = response.PeriodJourney;

			userJourneys.value.push(userJourney);

		}
		else {
			loadStart();
			const request = new GetUserPreviousWorkJourney();
			const response = await request.execute();

			if (!response.Success) {
				notify(DefaultError(response.Errors[0].Message));
				console.error(response.Errors[0].Message);
			}
			else {

				for (const journey of response.Result.Records) {
					let userJourney = new Journey();

					userJourney.JourneyType = journey.JourneyType;
					userJourney.startDate = moment(journey.StartDate).format("DD/MM/YYYY");
					userJourney.HoursJourney = journey.HoursJourney;

					if (userJourney.JourneyType === JourneyType.Period)
						userJourney.PeriodJourney = journey.PeriodJourney;

					userJourneys.value.push(userJourney);

				}
				loadStop();
			}
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		isLoading.value = false;
	}
};

defineExpose({
	openJourneyModal,
});

onMounted(() => loadJourney());

</script>

<style lang="scss">

@import "@/styles/Variables.scss";

</style>