import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class DeleteTaskRequest {

	TaskId = "";

	constructor(id: string) {
		this.TaskId = id;
	}

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.delete("/task", {
			params: this,
		});
		return response.data;
	}
}