import { RouteRecordRaw } from "vue-router";
import RouteName from "@/router/routes-name";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/minhas-justificativas",
		name: RouteName.JUSTIFICATIONS,
		meta: {
			title: "Justificativas",
		},
		component: () => import(/* webpackChunkName: "justifications" */ "@/views/Justifications/JustificationsView.vue"),
	},
];

export default routes;