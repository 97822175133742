<script setup lang="ts">
import { computed, PropType, ref, defineProps } from "vue";
import { CommentLog, TaskLog } from "@/models/Components/TaskLog";
import { AuthGetter } from "@/store/modules/auth/getters";
import store from "@/store";
import moment from "moment/moment";
import { DefaultError, DefaultWarn, getImageURL, NotificationType } from "@/utils/general";
import { UserInfo } from "@/models/User/UserInfo";
import { notify } from "@kyvg/vue3-notification";
import CreateTaskLogRequest from "@/api/requests/task/log/CreateTaskLogCommentRequest";
import DeleteTaskLogCommentRequest from "@/api/requests/task/log/DeleteTaskLogCommentRequest";
import UserImage from "@/components/UserImage.vue";
import OwnIcon from "@/components/OwnIcon.vue";


const props = defineProps({
	entries: {
		type: Object as PropType<TaskLog[]>,
		required: true,
	},
	creator: {
		type: Object as PropType<UserInfo>,
		required: true,
	},
	createdDate: {
		type: String,
		required: true,
	},
});
const username = computed(() => store.getters[AuthGetter.USERNAME]);
const userId = computed(() => store.getters[AuthGetter.USER_INFO].UserId);
const imageId = computed(() => store.getters[AuthGetter.IMAGE_ID]);
const imageUrl = computed(() => imageId.value !== null ? getImageURL(imageId.value) : "");

const newComment = ref("");

let textRandomCreate = "No primeiro dia, o usuário criou a atividade; e viu ele que era bom.";

let randomCreate = Math.floor(Math.random() * 100);

if (randomCreate > 20)
	textRandomCreate = "Como num passe de mágica.";

if (randomCreate > 40)
	textRandomCreate = "Fruto de uma inteligência artificial avançadíssima.";

if (randomCreate > 60)
	textRandomCreate = "Talvez um viajante do tempo não registrado temendo a revolução das máquinas.";

if (randomCreate > 80)
	textRandomCreate = "Simplesmente surgiu no banco de dados do mágico da TI.";

function handleInsert(logEntry: TaskLog) {
	logEntry.InputCommentOpen = true;
}

function handleCancelSend(logEntry: TaskLog) {
	logEntry.InputCommentOpen = false;
}

async function sendComment(logEntry: TaskLog) {

	let comment = new CommentLog();
	comment.IsLoadingSend = true;
	comment.CreatedBy.Name = username.value;
	comment.CreatedBy.Id = userId.value;
	comment.CreatedBy.ImageUrl = getImageURL(imageId.value);
	comment.Message = newComment.value;
	comment.CreatedDate = moment().locale("pt-br").format("llll");
	comment.CreatedDateShort = moment().locale("pt-br").fromNow();

	logEntry.Comments.unshift(comment);
	logEntry.IsShowingComments = true;
	logEntry.InputCommentOpen = false;

	let showError = false;

	try {
		const request = new CreateTaskLogRequest();
		request.TaskLogId = logEntry.Id;
		request.Commentary = comment.Message;
		const response = await request.execute();

		comment.Record = response.Record;

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
			showError = true;
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Você adicionou um comentário ao registro de alteração de ${logEntry.CreatedBy.Name}.`,
			});
		}

	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);

		showError = true;
	}
	finally {
		comment.IsLoadingSend = false;

		if (showError) {
			comment.CanDelete = false;
			comment.CreatedDate = "Não foi possível adicionar o comentário";
			comment.CreatedDateShort = "Não foi possível adicionar o comentário";
			logEntry.InputCommentOpen = true;
		}
		else {
			comment.CanDelete = true;
			newComment.value = "";
		}

		logEntry.Comments.shift();
		logEntry.Comments.unshift(comment);
	}
}

async function handleDeleteComment(commentEntry: CommentLog, toDelete: boolean) {
	commentEntry.IsLoadingDelete = true;
	try {
		const request = new DeleteTaskLogCommentRequest();
		request.Record = commentEntry.Record;
		request.IsDeleted = !toDelete;
		const response = await request.execute();

		if (!response.Success) {
			console.error(response.Errors[0].Message);
			notify(DefaultError(response.Errors[0].Message));
		}
		else {
			notify({
				type: NotificationType.SUCCESS,
				title: "Sucesso",
				text: `Você ${toDelete ? "excluiu" : "restaurou"} seu comentário.`,
			});

			commentEntry.IsDeleted = toDelete;
		}
	}
	catch (err) {
		console.warn(err);
		notify(DefaultWarn);
	}
	finally {
		commentEntry.IsLoadingDelete = false;
	}
}

</script>

<template>
	<section id="log">
		<div class="log-item" v-for="(log, idx) in entries" :key="idx">
			<div class="log-main">
				<div class="log-body-container">
					<UserImage v-if="log.CreatedBy.ImageUrl" :name="log.CreatedBy.Name" :img-url="log.CreatedBy.ImageUrl" size="medium" />
					<OwnIcon v-else type="memories" extra-large/>
					<div class="log-body">
						<span class="log-author">
							{{ log.CreatedBy.Name }}
						</span>
						<span class="log-text" v-for="(entry, idx_e) in log.Entries" :key="idx_e" v-html="entry.Message">
						</span>
						<div class="log-date" :title="log.CreatedDate">
							{{ log.CreatedDateShort }}
						</div>
					</div>
				</div>
				<button v-if="log.CanComment && !log.InputCommentOpen" class="btn btn-secondary btn-small btn-show-comments" title="Inserir comentário" @click="handleInsert(log)">
					<OwnIcon type="chat-new" />
				</button>
			</div>
			<div class="log-comment" v-if="log.InputCommentOpen">
				<OwnIcon type="corner-down-right" />
				<UserImage size="medium" :name="username" :img-url="imageUrl"></UserImage>
				<form class="input-group" @submit.prevent="sendComment(log)">
					<input class="form-control" type="text" maxlength="512" placeholder="Insira seu comentário" v-model="newComment" ref="inputRefs">
					<button class="btn btn-secondary btn-send" title="Enviar">
						<OwnIcon type="send-plane" class="me-2" />
					</button>
				</form>
				<button class="btn btn-close btn-small" title="Cancelar envio" @click="handleCancelSend(log)"></button>
			</div>
			<template v-if="!log.IsShowingComments">
				<div class="log-comment" v-if="log.Comments.length > 0">
					<OwnIcon type="corner-down-right" />
					<button class="btn btn-invisible" @click="log.IsShowingComments = !log.IsShowingComments">
						Exibir {{ log.Comments.length }} {{ log.Comments.length == 1 ? "comentário" : "comentários" }}
					</button>
				</div>
			</template>
			<template v-else>
				<div class="log-comment" v-for="(comment, idx_c) in log.Comments" :key="idx_c">
					<OwnIcon type="corner-down-right" />
					<span class="comment-body-container">
						<UserImage :name="comment.CreatedBy.Name" :img-url="comment.CreatedBy.ImageUrl" :size="'medium'"></UserImage>
						<span class="comment-body">
							<span class="comment-author">
								{{ comment.CreatedBy.Name }}
							</span>
							<span class="comment-text" v-if="!comment.IsDeleted">
							   {{ comment.Message }}
							</span>
							<span class="comment-text" v-else>
								<em>Comentário excluído.</em>
							</span>
							<span class="comment-date" v-if="comment.IsLoadingSend">
								<span class="spinner-border spinner-border-sm" role="status"></span>
								Enviando comentário...
							</span>
							<span class="comment-date" v-else :title="comment.CreatedDate">
								{{ comment.CreatedDateShort }}
							</span>
						</span>
						<button class="btn btn-invisible btn-exclude" v-if="!comment.IsLoadingDelete && comment.CanDelete && !comment.IsDeleted" @click="handleDeleteComment(comment, true)" title="Excluir">
							<OwnIcon type="delete-bin-2" />
						</button>
						<button class="btn btn-invisible btn-restore" v-if="!comment.IsLoadingDelete && comment.IsDeleted && comment.CreatedBy.Id === userId" @click="handleDeleteComment(comment, false)" title="Restaurar">
							<OwnIcon type="arrow-go-back" />
						</button>
						<span class="spinner-border spinner-border-sm" role="status" v-if="comment.IsLoadingDelete">
							<span class="visually-hidden"> {{ comment.IsDeleted ? "Restaurando" : "Excluindo" }}</span>
						</span>
					</span>
				</div>
			</template>
		</div>
		<div class="log-item">
			<div class="log-main">
				<div class="log-body-container">
					<UserImage v-if="creator?.Id != null" :name="creator.Name" :img-url="creator.ImageUrl" :size="'medium'" />
					<OwnIcon type="user-3" v-else />
					<div class="log-body">
						<span class="log-author">
							{{ creator.Name ?? "Usuário desconhecido" }}
						</span>
						<span class="log-text">
							Criou a atividade.
							<template v-if="!creator.Name">
								<br>
								<em class="small">{{ textRandomCreate }}</em>
							</template>
						</span>
						<div class="log-date">
							{{ createdDate ?? "Em algum momento" }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss">
@import "@/styles/Variables.scss";

section#log {
	padding: 0.5rem 1.5rem 1.5rem;
	display: grid;
	gap: .75rem;

	.log-item {
		display: grid;
		gap: 0.75rem;
	}

	.log-main {
		display: flex;
		align-items: center;
		gap: 1rem;
		background-color: $ot-background-secondary;
		padding: 0.5rem 1rem;
		border-radius: 12px;
		justify-content: space-between;
	}

	.log-body-container,
	.comment-body-container {
		display: flex;
		align-items: center;
		gap: 0.75rem;
	}

	.log-body {
		display: flex;
		flex-direction: column;

		.log-text {
			i {
				margin: 0;
			}

			a {
				color: $ot-text-primary;
				text-decoration: none;

				&:hover {
					color: $ot-highlight-active
				}
			}
		}
	}

	.log-date,
	.comment-date {
		font-size: 0.85rem;
		color: $ot-text-secondary;
	}

	.log-comment {
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	.text-comments-not-show {
		display: none;
	}

	@media (max-width: 550px) {
		.log-comment,
		.comment-body-container {
			gap: 0.5rem;
		}

		.log-main {
			flex-direction: column;
			align-items: flex-start;

			.btn-show-comments {
				width: 100%;
			}
		}
	}

	@media (max-width: 450px) {
		.log-comment .img-user {
			display: none;
		}
	}

	@media (max-width: 350px) {
		.log-main .img-user {
			display: none;
		}
	}

	.log-author,
	.comment-author {
		font-weight: 800;
	}

	.comment-body {
		display: flex;
		flex-direction: column;
		padding: 0.5rem 1rem;
		border-radius: 12px;
		background-color: $ot-background-secondary;
	}

	.comment-date {
		white-space: nowrap;
	}

	.btn-send {
		min-width: unset;
	}

	.btn-exclude:hover {
		color: $ot-danger
	}

	.btn-restore:hover {
		color: $ot-highlight-active
	}
}

body:has(#app > main.dark) section#log {
	.log-date,
	.comment-date {
		color: $ot-dark-text-secondary;
	}

	.log-body .log-text a {
		color: $ot-dark-text-primary;

		&:hover {
			color: $ot-highlight-active
		}
	}
}
</style>