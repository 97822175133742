import Api from "@/api";
import { DefaultBooleanResponseResult } from "@/api/responses/generic/DefaultBooleanResponse";

export default class GetTodayHoursBankRequest {
	
	async execute(): Promise<DefaultBooleanResponseResult<BankHoursInfo>> {
		const response = await Api.get("/bank-hours/today");
		return response.data;
	}
}

interface BankHoursInfo {
	WorkJourney: string,
	RegisteredTime: string,
	HoursBank: string,
	LastRegister: number,
	IsInVacation: boolean,
}
