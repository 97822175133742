import Api from "@/api";
import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import { TaskTypes } from "@/api/requests/task/EditTaskRequest";
import { TagInfo } from "@/api/requests/tag/GetTagsRequest";

export default class GetNearTasksRequest {

	async execute(): Promise<DefaultListResponse<NearTask>> {
		const response = await Api.get("/task/near-tasks");
		return response.data;
	}
}

interface NearTask {
	TaskId: string;
	TaskName: string;
	ProjectName: string;
	ProjectColor: string,
	TeamName: string,
	TaskDescription: string;
	BeginDate: string;
	EndDate: string;
	TaskStatus: TaskStatus;
	Points: number,
	IsLate: boolean,
	TaskMembers: UserNearTask[]
	Type: TaskTypes
	Effort: number
	Tags: TagInfo[]
}

export enum TaskStatus {
	AIniciar = 1,
	AFinalizar = 2
}

interface UserNearTask {
	UserId: string,
	ImageId: string,
	Name: string
}