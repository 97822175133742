import { UserInfo } from "@/models/User/UserInfo";
import { TaskTypes } from "@/api/requests/task/EditTaskRequest";
import moment from "moment";

export class TaskLog {
	Id = "";
	CreatedBy: UserInfo = new UserInfo();
	CreatedDate = "";
	CreatedDateShort = "";
	Entries: LogEntry[] = [];
	IsShowingComments = false;
	CanComment = false;
	InputCommentOpen = false;
	Comments: CommentLog[] = [];
}

export enum TaskLogType {
	Undefined = 0,
	TaskTitle,
	RemoveUser,
	AddUser,
	AddBeginDate,
	ChangeBeginDate,
	RemoveBeginDate,
	AddEndDate,
	ChangeEndDate,
	RemoveEndDate,
	ChangeState,
	AddProject,
	ChangeProject,
	RemoveProject,
	AddTeam,
	ChangeTeam,
	RemoveTeam,
	ChangePoints,
	AddDescription,
	ChangeDescription,
	RemoveDescription,
	AddChecklistItem,
	RemoveChecklistItem,
	CheckListItemDone,
	CheckListItemNotDone,
	ChangeCheckListItemDescription,
	ChangeTaskVisibility,
	ArchiveTask,
	UnarchiveTask,
	ChangeMeetingPlace,
	ChangeMeetingType,
	ChangeMeetingLink,
	AddEffort,
	ChangeEffort,
	RemoveEffort,
	AddTag,
	RemoveTag,
}

export class CommentLog {
	Record = 0;
	CreatedBy: UserInfo = new UserInfo();
	CreatedDate = "";
	CreatedDateShort = "";
	Message = "";
	IsDeleted = false;
	CanDelete = false;
	IsLoadingSend = false;
	IsLoadingDelete = false;
}

export class LogEntry {
	OldValue = "";
	OldValueId = "";
	NewValue = "";
	NewValueId = "";
	Type: TaskLogType = TaskLogType.Undefined;
	Message = "";
	TaskType: TaskTypes = TaskTypes.Task;
	Log: TaskLog = new TaskLog();

	public generateMessageByType(): string {
		switch (this.Type) {
			case TaskLogType.TaskTitle:
				return `Alterou o título de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.RemoveUser:
				return `Removeu <strong><a target="_blank" href="usuarios/${this.OldValueId}"><i class="ri-user-3-line"></i> ${this.OldValue}</a></strong> dos membros.`;

			case TaskLogType.AddUser:
				return `Adicionou <strong><a target="_blank" href="usuarios/${this.NewValueId}"><i class="ri-user-3-line"></i> ${this.NewValue}</a></strong> aos membros.`;

			case TaskLogType.AddBeginDate:
				this.NewValue = moment(this.NewValue).locale("pt-br").format("lll");
				return `Adicionou a data de início para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.ChangeBeginDate:
				this.NewValue = moment(this.NewValue).locale("pt-br").format("lll");
				this.OldValue = moment(this.OldValue).locale("pt-br").format("lll");
				return `Alterou a data de início de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.RemoveBeginDate:
				this.OldValue = moment(this.OldValue).locale("pt-br").format("lll");
				return `Removeu a data de início (<strong>${this.OldValue}</strong>).`;

			case TaskLogType.AddEndDate:
				this.NewValue = moment(this.NewValue).locale("pt-br").format("lll");
				return `Adicionou a data de entrega para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.ChangeEndDate:
				this.OldValue = moment(this.OldValue).locale("pt-br").format("lll");
				this.NewValue = moment(this.NewValue).locale("pt-br").format("lll");
				return `Alterou a data de entrega de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.RemoveEndDate:
				this.OldValue = moment(this.OldValue).locale("pt-br").format("lll");
				return `Removeu a data de entrega (<strong>${this.OldValue}</strong>).`;

			case TaskLogType.ChangeState:
				return `Alterou a etapa de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.AddProject:
				return `Adicionou o projeto <strong><a target="_blank" href="projetos/${this.NewValueId}"><i class="ri-folder-2-line"></i> ${this.NewValue}</a></strong>.`;

			case TaskLogType.ChangeProject:
				return `Alterou o projeto de <strong><a target="_blank" href="projetos/${this.OldValueId}"><i class="ri-folder-2-line"></i> ${this.OldValue}</a></strong> para <strong><a target="_blank" href="projetos/${this.NewValueId}"><i class="ri-folder-2-line"></i> ${this.NewValue}</a></strong>.`;

			case TaskLogType.RemoveProject:
				return `Removeu o projeto <strong><a target="_blank" href="projetos/${this.OldValueId}"><i class="ri-folder-2-line"></i> ${this.OldValue}</a></strong>.`;

			case TaskLogType.AddTeam:
				return `Adicionou a equipe <strong><a target="_blank" href="equipes/${this.NewValueId}"><i class="ri-group-2-line"></i> ${this.NewValue}</a></strong>.`;

			case TaskLogType.ChangeTeam:
				return `Alterou a equipe de <strong><a target="_blank" href="equipes/${this.OldValueId}"><i class="ri-group-2-line"></i> ${this.OldValue}</a></strong> para <strong><a target="_blank" href="equipes/${this.NewValueId}"><i class="ri-group-2-line"></i> ${this.NewValue}</a></strong>.`;

			case TaskLogType.RemoveTeam:
				return `Removeu a equipe <strong><a target="_blank" href="${this.OldValueId}"><i class="ri-group-2-line"></i> ${this.OldValue}</a></strong>.`;

			case TaskLogType.ChangePoints:
				return `Alterou os pontos de <i class="ri-bookmark-fill"></i> <strong>${this.OldValue}</strong> para <i class="ri-bookmark-fill"></i> <strong>${this.NewValue}</strong>.`;

			case TaskLogType.AddDescription:
				return `Adicionou uma descrição.`;

			case TaskLogType.ChangeDescription:
				return `Alterou a descrição.`;

			case TaskLogType.RemoveDescription:
				return `Removeu a descrição.`;

			case TaskLogType.AddChecklistItem:
				return `Adicionou o item <strong>${this.NewValue}</strong> ao checklist.`;

			case TaskLogType.RemoveChecklistItem:
				return `Removeu o item <strong>${this.OldValue}</strong> do checklist.`;

			case TaskLogType.CheckListItemDone:
				return `Marcou o item <strong>${this.OldValue}</strong> do checklist como <i class="ri-checkbox-line"></i> Concluído.`;

			case TaskLogType.CheckListItemNotDone:
				return `Marcou o item <strong>${this.OldValue}</strong> do checklist como <i class="ri-checkbox-blank-line"></i> Inconcluído.`;

			case TaskLogType.ChangeCheckListItemDescription:
				return `Alterou a descrição do item <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.ChangeTaskVisibility:
				return `Alterou a privacidade de <strong>${
						this.OldValue == "True" ?
								`<i class="ri-lock-line"></i> Privada` :
								`<i class="ri-earth-line"></i> Pública`
				}</strong> para <strong>${
						this.NewValue == "True" ?
								`<i class="ri-lock-line"></i> Privada` :
								`<i class="ri-earth-line"></i> Pública`
				}</strong>.`;

			case TaskLogType.ArchiveTask:
				return `<i class="ri-archive-line"></i> Arquivou a ${this.TaskType == TaskTypes.Task ? "atividade" : "reunião"}.`;

			case TaskLogType.UnarchiveTask:
				return `<i class="ri-inbox-unarchive-line"></i> Desarquivou a ${this.TaskType == TaskTypes.Task ? "atividade" : "reunião"}.`;

			case TaskLogType.ChangeMeetingPlace:
				return `Alterou o local de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.ChangeMeetingType:
				return `Alterou o tipo de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.ChangeMeetingLink:
				return `Alterou o link de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong>.`;

			case TaskLogType.AddEffort:
				return `Adicionou <strong>${this.NewValue}</strong> horas ao <i class="ri-timer-flash-line"></i> Esforço.`;

			case TaskLogType.ChangeEffort:
				return `Alterou o <i class="ri-timer-flash-line"></i> Esforço de <strong>${this.OldValue}</strong> para <strong>${this.NewValue}</strong> horas.`;

			case TaskLogType.RemoveEffort:
				return `Removeu o <i class="ri-timer-flash-line"></i> Esforço.`;

			case TaskLogType.AddTag:
				return `Adicionou a etiqueta <strong>${this.NewValue}</strong>.`;

			case TaskLogType.RemoveTag:
				return `Removeu a etiqueta <strong>${this.OldValue}</strong>.`;

			default:
				return `Alterou alguma coisa desconhecida. Comunique o administrador.<br>
						Tipo: ${this.Type}. Valor antigo: ${this.OldValue}. Valor novo: ${this.NewValue}`;
		}
	}
}