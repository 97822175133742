import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";
import Api from "@/api";

export default class CreateTaskRecordTimeRequest {
	TaskId = "";
	Date = "";
	Time = "";

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.post("/task-recorded-time", this);
		return response.data;
	}
}