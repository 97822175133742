import Api from "@/api";

export default class HasNotificationRequest {

	async execute(): Promise<HasNotification> {
		const response = await Api.get("/notifications/has-notification");
		return response.data;
	}
}

interface HasNotification {
	HasNotification: boolean;
	TotalNotificationNotReaded: number;
}