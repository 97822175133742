import DefaultListResponse from "../../responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetMeetingRoomListRequest {
	
	async execute(): Promise<DefaultListResponse<GetMeetingRoomList>> {
		const response = await Api.get("/meeting-room/list");
		return response.data;
	}
}

interface GetMeetingRoomList {
	MeetingRoomId: string,
	Name: string,
	MaxParticipant: number,
}