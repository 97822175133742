import Api from "@/api";
import { MeetingTypes, TaskTypes } from "@/api/requests/task/EditTaskRequest";
import { DefaultBooleanResponseResult } from "@/api/responses/generic/DefaultBooleanResponse";

export default class GetTaskRequest {

	TaskId = "";

	constructor(taskId = "") {
		this.TaskId = taskId;
	}

	async execute(): Promise<DefaultBooleanResponseResult<Task>> {
		const response = await Api.get("/task/get-task", {
			params: this,
		});
		return response.data;
	}
}


interface Task {
	TaskId: string;
	CreatedByUser: CreatedByUser;
	CreatedDate: string;
	TaskName: string;
	Description: string;
	TeamId: string;
	TeamName: string;
	ProjectId: string;
	ProjectName: string;
	Points: number;
	Stage: StageTypes;
	BeginDate: string;
	EndDate: string;
	TaskMember: UsersInformation[];
	CheckListItems: CheckListItems[];
	MeetingType: MeetingTypes;
	MeetingPlace: "";
	TaskType: TaskTypes;
	CanEditTask: boolean;
	CanAddHours: boolean;
	IsPrivate: boolean;
	Logs: Logs[];
	Effort: number;
	Tags: Tags[];
	MeetingRoomId: string;
	MeetingRoomName: string;
	MaxParticipant: number;
	IsDeleted: boolean;
}

interface CreatedByUser {
	UserId: string;
	ImageId: string;
	Name: string;
	IsDeleted: true;
}

interface CreatedBySprint {
	SprintId: string;
	Name: string;
}

export interface UsersInformation {
	UserId: string;
	ImageId: string;
	UserName: string;
	IsConfirmed: boolean;
}

export interface CheckListItems {
	Record: number | null;
	Description: string;
	Done: boolean;
}

export enum StageTypes {
	Backlog = 0,
	AFazer = 1,
	Fazendo = 2,
	Verificar = 3,
	Feito = 4,
}

interface Logs {
	TaskLogId: string;
	CreatedByUser: CreatedByUser;
	CreatedBySprint: CreatedBySprint;
	CreatedDate: string;
	LogEntries: LogEntries[];
	LogCommentaries: LogCommentaries[];
}

interface LogEntries {
	MessageType: number;
	OldValue: string;
	OldValueId: string;
	NewValue: string;
	NewValueId: string;
}

interface LogCommentaries {
	Record: 0;
	CreatedByUser: CreatedByUser;
	Commentary: string;
	CreatedDate: string;
	CanDelete: true;
	IsDeleted: true;
}

interface Tags {
	TagId: string;
	Name: string;
	Color: string;
}