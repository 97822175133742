import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";
import Api from "@/api";
import { CheckListItem } from "@/models/Tasks/TasksView";


export default class CreateTaskRequest {
	TeamId: string | null = "";
	ProjectId: string | null = "";
	Points = 0;
	Stage = 1;
	Name = "";
	Description = "";
	BeginDate = "";
	EndDate = "";
	TaskType = 1;
	Place = "";
	MeetingType = 1;
	TaskMembers: string[] = [];
	CheckListItems: CheckListItem[] = [];
	IsPrivate = false;
	Effort: number | null = null;
	MeetingRoomId: string | null = null;
	Tags: string[] = []

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.post("/task", this);
		return response.data;
	}
}