import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetAbsencesRequest {
	Page = 1;
	ItensPerPage = 15;

	constructor(page = 1, results = 15) {
		this.Page = page;
		this.ItensPerPage = results;
	}

	async execute(): Promise<DefaultListResponse<AbsenceInfo>> {
		const response = await Api.get("/absence-justification/all", {
			params: this,
		});
		return response.data;
	}
}

interface AbsenceInfo {
	AbsenceJustificationId: string;
	UserId: string;
	ImageId: string;
	Name: string;
	Message: string;
	BeginDate: string;
	EndDate: string;
	CreatedDate: string;
	Status: AbsenceStatus;
	RepliedBy: string;
	UserNameReplied: string;
	ReplyDate: string;
	Type: AbsenceType;
}

export enum AbsenceStatus {
	Pendente = 1,
	Aprovado = 2,
	Reprovado = 3,
	Cancelado = 4,
}

export enum AbsenceType {
	FalecimentoFamiliar = 1,
	Casamento,
	NascimentoFilho,
	DoacaoSangue,
	AlistamentoEleitoral,
	ServicoMilitar,
	Vestibular,
	Juizo,
	RepresentacaoSindical,
	ConsultaMedicaFilho,
	ConsultaMedicaEsposa,
	ExameMedicoCancer,
	AtestadoMedico,
	Greve,
	LicencaMaternidade,
	Mesario,
	DoacaoLeiteMaterno,
	ProblemaTransportePublico,
	Outros,
}