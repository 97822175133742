import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class DeleteTaskRecordedTimeRequest {
	TaskRecordedTimeId = "";

	constructor(id: string) {
		this.TaskRecordedTimeId = id;
	}

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.delete("/task-recorded-time", {
			params: this,
		});
		return response.data;
	}
}