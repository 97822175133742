import { config, Footers, ToolbarNames } from "md-editor-v3";

export const ToolbarOptions: ToolbarNames[] = [
	"bold",
	"underline",
	"strikeThrough",
	"italic",
	"-",
	"title",
	"strikeThrough",
	"sub",
	"sup",
	"quote",
	"unorderedList",
	"orderedList",
	"=",
	"revoke",
	"next",
	"-",
	"link",
	"image",
	"table",
	"mermaid",
	"katex",
	"-",
	// "pageFullscreen",
	// "fullscreen",
	"preview",
	// "htmlPreview",
];

export const FooterOptions: Footers[] = [];

config({
	editorConfig: {
		languageUserDefined: {
			'pt-BR': {
				toolbarTips: {
					bold: 'Negrito',
					underline: 'Sublinhado',
					italic: 'Itálico',
					strikeThrough: 'Tachado',
					title: 'Título',
					sub: 'Subscrito',
					sup: 'Sobrescrito',
					quote: 'Citação',
					unorderedList: 'Lista não ordenada',
					orderedList: 'Lista ordenada',
					task: 'Lista de atividade',
					codeRow: 'Linha de Código',
					code: 'Bloco de código',
					link: 'Link',
					image: 'Imagem',
					table: 'Tabela',
					mermaid: 'Diagrama',
					katex: 'Fórmula',
					revoke: 'Desfazer',
					next: 'Refazer',
					save: 'Salvar',
					prettier: 'Prettier',
					pageFullscreen: 'Tela cheia na página',
					fullscreen: 'Tela cheia',
					preview: 'Pré-visualização',
					htmlPreview: 'Pré-visualização HTML',
					catalog: 'Índice',
					github: 'Código-fonte'
				},
				titleItem: {
					h1: 'Título Lv1',
					h2: 'Título Lv2',
					h3: 'Título Lv3',
					h4: 'Título Lv4',
					h5: 'Título Lv5',
					h6: 'Título Lv6'
				},
				imgTitleItem: {
					link: 'Adicionar imagem por link',
					upload: 'Carregar imagem',
					clip2upload: 'Colar imagem'
				},
				linkModalTips: {
					linkTitle: 'Adicionar link',
					imageTitle: 'Adicionar imagem',
					descLabel: 'Descrição:',
					descLabelPlaceHolder: 'Insira a descrição...',
					urlLabel: 'Link:',
					urlLabelPlaceHolder: 'Insira o link...',
					buttonOK: 'OK'
				},
				clipModalTips: {
					title: 'Cortar imagem',
					buttonUpload: 'Carregar'
				},
				copyCode: {
					text: 'Copiar',
					successTips: 'Copiado!',
					failTips: 'Cópia falhou!'
				},
				mermaid: {
					flow: 'Fluxo',
					sequence: 'Sequência',
					gantt: 'Gantt',
					class: 'Classe',
					state: 'Estado',
					pie: 'Pizza',
					relationship: 'Relacionamento',
					journey: 'Jornada'
				},
				katex: {
					inline: 'Linha',
					block: 'Bloco'
				},
				footer: {
					markdownTotal: 'Caracteres',
					scrollAuto: 'Rolagem automática'
				}
			}
		}
	}
});