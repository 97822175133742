const loadingEl: HTMLDialogElement = document.getElementById("loading") as HTMLDialogElement;

/**
 * Inicia a animação de carregamento
 */
export const loadStart = (): void => {
	if (loadingEl !== null)
		(loadingEl as any).showModal();
};

/**
 * Finaliza a animação de carregamento
 */
export const loadStop = (): void => {
	if (loadingEl !== null)
		(loadingEl as any).close();
};