import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";
import { CheckListItems, StageTypes } from "./GetTaskRequest";

export default class EditTaskRequest {
	TaskId = "";
	TeamId: string | null = "";
	ProjectId: string | null = "";
	Points = 0;
	Stage: StageTypes = 0;
	Name = "";
	Description = "";
	BeginDate = "";
	EndDate = "";
	Place = "";
	MeetingType: MeetingTypes = 1;
	TaskMembers: string[] = [];
	CheckListItems: CheckListItems[] = [];
	TaskType: TaskTypes = 1;
	IsPrivate = false;
	Effort: number | null = null;
	MeetingRoomId: string | null = null;
	Tags: string[] = []

	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.put("/task", this);
		return response.data;
	}
}

export enum MeetingTypes {
	Remote = 1,
	Presential = 2,
	MeetingRoom = 3
}

export enum TaskTypes {
	Task = 1,
	Meeting = 2,
}