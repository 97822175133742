import UserInfoDto from "@/api/responses/auth/UserInfoDto";

export interface IAuthState {
	userInfo: UserInfoDto | null;
	isUpdatingUserInfo: boolean;
	isDarkMode: boolean | null;
}

export const state = (): IAuthState => {
	return {
		userInfo: null,
		isUpdatingUserInfo: false,
		isDarkMode: null,
	};
};