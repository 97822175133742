import Api from "@/api";
import { DefaultBooleanResponse } from "@/api/responses/generic/DefaultBooleanResponse";

export default class EditMeetingConfirmationRequest {

	TaskId = "";
	IsConfirmed = false;
	async execute(): Promise<DefaultBooleanResponse> {
		const response = await Api.put("/task-members", this);
		return response.data;
	}
}
