import DefaultListResponse from "@/api/responses/generic/DefaultListResponse";
import Api from "@/api";

export default class GetTeamsInfoRequest {
	
	
	async execute(): Promise<DefaultListResponse<TeamInfo>> {
		const response = await Api.get("/teams/get-teams-info");
		return response.data;
	}
}

interface TeamInfo {
	TeamId: string;
	Name: string;
	UserIsPartOfTheTeam: boolean
}